import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogActions, Radio, Typography, Grid, DialogContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { WithUserId } from 'src/auth/Auth';
import * as Backend from 'src/util/firebase';
import { withProgress } from 'src/util/ProgressPromise';
import { Event, Course, Facility, SimpleCourse, Tee, HolesType, EventData, Team, Contact, getCourseName, isRound, HOLES_18 } from 'src/types/EventTypes';
import { elogInTransaction, updateGolfersPlayingHandicapInTransaction } from 'src/event/Event';
import { XSMobileDialog } from 'src/common/dialog/MobileDialog';
import DialogAppBar from 'src/common/dialog/DialogAppBar';
import AppButton from 'src/common/components/AppButton';
import EditTeesListDialog from 'src/event/tabs/common/EditTeesListDialog';
import { appStyles, styles } from 'src/styles';
import { CourseSetupPage } from 'src/event/list/CourseSetupPage';
import { Spacing } from 'src/common/Misc';

interface CourseViewProps {
    course: SimpleCourse;
    setAsOut: (course: SimpleCourse) => void;
    setAsIn: (course: SimpleCourse) => void;
    outNine: boolean;
    inNine: boolean;
}

export const CourseViewHeaderLong = withStyles(styles)((props: WithStyles<typeof styles>) => {
    return (
        <Grid container>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography>FRONT</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography>BACK</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography /></Grid>
        </Grid>
    );
});

export const CourseViewHeaderShort = withStyles(styles)((props: WithStyles<typeof styles>) => {
    return (
        <Grid container>
            <Grid item xs={4} className={props.classes.childrenCenteredLeft}><Typography>PLAY THIS NINE</Typography></Grid>
            <Grid item xs={2} className={props.classes.childrenCenteredLeft}><Typography /></Grid>
        </Grid>
    );
});

export const CourseViewLong = (props: CourseViewProps) => {
    const classes = appStyles();
    return (
        <Grid container>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsOut(props.course)}>
                <Radio color="secondary" checked={props.outNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Radio color="secondary" checked={props.inNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => { props.setAsOut(props.course); props.setAsIn(props.course); }}>
                <Typography style={{ marginLeft: -30 }}>{props.course.name}</Typography>
            </Grid>
        </Grid>
    );
};

export const CourseViewShort = (props: CourseViewProps) => {
    const classes = appStyles();
    return (
        <Grid container>
            <Grid item xs={1} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Radio color="secondary" checked={props.inNine} />
            </Grid>
            <Grid item xs={2} className={classes.childrenCenteredLeft + ' ' + classes.clickable}
                onClick={() => props.setAsIn(props.course)}>
                <Typography>{props.course.name}</Typography>
            </Grid>
        </Grid>
    );
};

interface CourseSelectionDialogProps extends DialogProps {
    event: Event;
    eventData: EventData;
    preselectedFacility?: Facility;
    preselectedHolesType?: HolesType;
}

interface State {
    course?: Course;
    holesType?: HolesType;
    teeMen?: Tee;
    teeWomen?: Tee;
    lastCourseName: string;
    editingTees?: boolean;
}

type Props = CourseSelectionDialogProps & WithStyles<typeof styles> & WithUserId;

class CourseSelectionDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { event, preselectedHolesType } = this.props;
        const { selectedRound } = this.props.eventData;
        const course = selectedRound?.course ?? event.course;
        const holesType = preselectedHolesType ?? selectedRound?.holesType ?? event.holesType ?? HOLES_18;
        const teeMen = selectedRound?.teeMen ?? event.teeMen;
        const teeWomen = selectedRound?.teeWomen ?? event.teeWomen;
        const lastCourseName = getCourseName(course);
        this.state = {
            course,
            holesType,
            teeMen,
            teeWomen,
            lastCourseName
        };
    }

    private readonly courseSetupPageRef: React.RefObject<CourseSetupPage> = React.createRef();

    public roundCourseIsMissing = () => this.courseSetupPageRef.current?.courseIsMissing();
    public closePopups = () => this.courseSetupPageRef.current?.closePopups();
    private handleEditScorecard = () => this.setState({ editingTees: true });
    private handleCloseEditScorecard = () => this.setState({ editingTees: false });

    private eventOrRound() {
        const { event } = this.props;
        const { selectedRound } = this.props.eventData;
        return selectedRound ?? event;
    }

    private handleSave = () => {
        const { course } = this.state;
        if (course) {
            const newName = getCourseName(course);
            const curName = getCourseName(this.eventOrRound().course);
            const clearTee = curName !== newName;
            this.save(clearTee, course);
        } else {
            this.save(false);
        }
    }

    private save = (clearTee: boolean, course?: Course) => {
        const { golfersMap, teamsMap, competitionsMap } = this.props.eventData;
        const { holesType, teeMen, teeWomen } = this.state;
        const eventOrRound = this.eventOrRound();
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const competitions = competitionsMap.get(eventOrRound.id) ?? [];
        const eventUpdate = {
            id: eventOrRound.id,
            handicapSystem: eventOrRound.handicapSystem,
            exists: true,
            teamSize: eventOrRound.teamSize
        } as Event;
        if (course) {
            eventUpdate.course = course;
            eventUpdate.teeMen = teeMen;
            eventUpdate.teeWomen = teeWomen;
            if (teeMen?.handicapSystem) {
                eventUpdate.handicapSystem = teeMen.handicapSystem;
            }
        }
        eventUpdate.holesType = holesType;
        if (eventUpdate.holesType !== eventOrRound.holesType) {
            eventUpdate.teeTime = { ...eventOrRound.teeTime };
            delete eventUpdate.teeTime.startingHoles;
            delete eventUpdate.teeTime.startingHolesType;
        }
        withProgress(Backend.withTransaction(transaction => {
            Backend.updateInTransaction(Backend.eventsDb, eventUpdate, transaction);
            elogInTransaction(transaction, eventOrRound, isRound(eventOrRound) ? 'Round course modified' : 'Course modified', 'Course: ' + getCourseName(course), `Id: ${eventUpdate.id}`);
            if (clearTee) {
                // TODO: remove
                // TODO: legacy competition tees
                /*competitions.forEach(competition => {
                    if (competition.tees) {
                        const competitionUpdate = { id: competition.id, exists: true } as Competition;
                        competitionUpdate.tees = [];
                        Backend.updateInTransaction(Backend.competitionsDb(eventOrRound.id), competitionUpdate, transaction);
                    }
                });*/
                golfers.forEach(contact => {
                    if (contact.tee) {
                        Backend.updateInTransaction(Backend.golferDb(eventOrRound.id), { id: contact.id, tee: undefined }, transaction);
                    }
                });
            }
            return updateGolfersPlayingHandicapInTransaction(transaction, eventUpdate, golfers, teams, competitions);
        })).then(this.handleClose);
    }

    private handleClose = () => {
        if (this.closePopups()) {
            return;
        }
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private courseUpdated = (course?: Course, holesType?: HolesType, teeMen?: Tee, teeWomen?: Tee) => {
        this.setState({ course, holesType, teeMen, teeWomen });
    };

    render() {
        const { classes, event } = this.props;
        const { course } = this.state;
        const { editingTees } = this.state;
        const saveDisabled = this.roundCourseIsMissing();
        return <>
            <XSMobileDialog open={this.props.open} onClose={this.handleClose} maxWidth="sm">
                <DialogAppBar label="Course" close={this.handleClose} />
                <DialogContent>
                    <CourseSetupPage
                        visible
                        event={event}
                        coursesActivated
                        course={event.course}
                        holesType={event.holesType}
                        defaultMenTee={event.teeMen?.id}
                        defaultWomenTee={event.teeWomen?.id}
                        courseUpdated={this.courseUpdated}
                        ref={this.courseSetupPageRef}
                    />
                    <Spacing />
                    <AppButton color="info" style={{ width: 122 }} onClick={this.handleEditScorecard} disabled={saveDisabled}>
                        <EditIcon sx={{ width: '.7em' }} /> &nbsp; View/edit
                    </AppButton>
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" className={classes.iconButton} onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave} disabled={saveDisabled}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
            {editingTees && course && <EditTeesListDialog course={course} event={event} close={this.handleCloseEditScorecard} />}
        </>;
    }
}

export default withStyles(styles)(CourseSelectionDialog);
