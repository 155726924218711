import * as React from 'react';
import { Event, Facility, getCourseName, HOLES_18, HOLES_9, HolesType, EventData } from '../../../../../types/EventTypes';
import { getCourse, loadCourseTees } from '../../../../Courses';
import CourseSelectionDialog from './CourseSelectionDialog';
import CourseSelectionMenu from './CourseSelectionMenu';
import LabeledField from '../../../../../common/form/LabeledField';
import { ListElem } from '../../../../../common/Misc';
import { withProgress } from '../../../../../util/ProgressPromise';
import { formatDateUniversal, formatTeesName } from 'src/event/Event';
import { DATETIME_FORMAT_ROUND1 } from 'src/util/config';

type Props = {
    event: Event;
    eventData: EventData;
};

interface State {
    courseDialogOpened?: boolean;
    anchorSelect?: any;
    selectedFacility?: Facility;
    holesType?: HolesType;
}

const COURSE_ELEM = 'course_elem';

class CourseSelectionField extends React.Component<Props, State> {
    state: State = {};

    private handleClose = () => this.setState({ courseDialogOpened: false, selectedFacility: undefined });

    private handleOpen = (e: React.MouseEvent) => {
        const { event, eventData } = this.props;
        const { selectedRound } = eventData;
        if (selectedRound || event.course) {
            this.setState({ courseDialogOpened: true, selectedFacility: undefined });
        } else {
            const elem = document.getElementById(COURSE_ELEM);
            this.setState({ anchorSelect: elem ? elem : e.currentTarget });
        }
    }

    private handleFacilityClose = () => {
        this.setState({ anchorSelect: null });
    }

    private handleFacilitySelect = async (selectedFacility: Facility) => {
        const { event } = this.props;
        const course = getCourse(selectedFacility);
        if (course) {
            withProgress(loadCourseTees(event.userId, course, false, true), true).then(tees => {
                const holesType = (selectedFacility.courses.length === 1 && tees.length > 0 && tees[0].par.length === 9 ? HOLES_9 : HOLES_18);
                this.setState({ courseDialogOpened: true, selectedFacility, holesType });
            }).catch(() => { });
        } else {
            const holesType = HOLES_18;
            this.setState({ courseDialogOpened: true, selectedFacility, holesType });
        }
        this.setState({ anchorSelect: null });
    }

    render() {
        const { event, eventData } = this.props;
        const { anchorSelect, courseDialogOpened, selectedFacility, holesType } = this.state;
        const { selectedRound } = eventData;
        const eventOrRound = selectedRound ?? event
        const courseName = getCourseName(eventOrRound.course);
        const label = selectedRound ? `Round ${selectedRound.roundOrder}` : 'Course';
        const sublabel = selectedRound ? 'Date: ' + formatDateUniversal(selectedRound.date, DATETIME_FORMAT_ROUND1) : '';
        const title = courseName || 'Not selected';
        const subtitle = formatTeesName(eventOrRound);
        return <>
            <LabeledField
                label={<ListElem title={label} boldTitle subtitle={sublabel} />}
                value={<ListElem id={COURSE_ELEM} title={title} subtitle={subtitle} color={courseName ? 'inherit' : 'error'} />}
                edit={this.handleOpen}
            />
            {courseDialogOpened && <CourseSelectionDialog
                open
                event={event}
                eventData={eventData}
                userId={event.userId}
                onClose={this.handleClose}
                preselectedFacility={selectedFacility}
                preselectedHolesType={holesType}
            />}
            {anchorSelect && <CourseSelectionMenu userId={event.userId} anchorSelect={anchorSelect}
                handleFacilitySelect={this.handleFacilitySelect} handleClose={this.handleFacilityClose} />}
        </>;
    }
}

export default CourseSelectionField;
