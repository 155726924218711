import React, { useRef, useState, useCallback } from 'react';
import update from 'immutability-helper'
import { Divider, Typography, IconButton, List, ListItem, Badge } from '@mui/material';
import { CSVLink } from 'react-csv';
import PeopleIcon from '@mui/icons-material/People';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {
    DragSource, DragSourceConnector, DragSourceMonitor, ConnectDragSource, ConnectDragPreview,
    DropTarget, DropTargetConnector, DropTargetMonitor, ConnectDropTarget
} from 'react-dnd';
import * as Utils from '../../../../util/utility';
import * as Backend from '../../../../util/firebase';
import { WithUserId, withUserId } from '../../../../auth/Auth';
import { withProgress } from '../../../../util/ProgressPromise';
import {
    Event, EventData, Contact, Team, ContactDetails, ScoringTeamSize, HandicapMode, GenderMode,
    makeNewGroups, golfersOrTeams, golfersOrTeamsPerGroup, EventBase, Distance, Score, ReportedScore,
    SAME_TEAMS_IN_ALL_ROUNDS
} from '../../../../types/EventTypes';
import { fullName, getSameNameGolfersIds } from '../../../../contact/Contact';
import {
    teamName, teamSizeName, addGolferToTeam, removeGolferFromTeam, setEventTeamSize,
    deleteGolfersFromEvent, saveContact, saveGroupsBatch, getUnpairedGolfersCount, visibleTeamContacts, visibleTeamContactIds, resetEventTeams, saveEventPairedTeams,
    elog
} from '../../../Event';
import EditTeamsDialog from './EditTeamsDialog';
import { FixedWidthChip } from '../../../../common/components/FixedWidthChip';
import EditContactDialog from '../../../../contact/EditContactDialog';
import AutoScheduleDialog from '../../common/AutoScheduleDialog';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import LabeledField from '../../../../common/form/LabeledField';
import SelectTeamSizeDialog from '../../common/SelectTeamSizeDialog';
import { ButtonBadge, Container, Item, NoWrapOverflow } from '../../../../common/Misc';
import { styles } from '../../../../styles';
import { AppColors } from 'src/main/Theme';
import { EmailVariant, sendPaymentMessage } from '../../../../util/email_utils';

const DRAG_TYPE_TEAM = 'TEAM';

export interface TeamWithContacts {
    team: Team;
    contacts: Array<Contact>;
}

interface TeamRowItem {
    id: string;
    name: string;
    homeCourseOrCity?: string;
    isPlaceholder: boolean;
    hidden: boolean;
    version: number;
}

interface TeamItemProps {
    golfers: Map<string, Contact>;
    team: TeamWithContacts;
    teamSize: number;
    teamFullness: number;
    initialItems: Array<TeamRowItem>;
    addToTeam: (team: TeamWithContacts) => void;
    addGolferToTeamFunc: (golfer: Contact, toTeam: Team, positionInTeam?: number) => void;
    dropToTeam: (golferId: string) => void;
    deleteFromTeam: (golferId: string) => void;
    openGolfer: (id: string) => void;
}

interface TeamContactItemProps {
    id: string;
    name: string;
    homeCourseOrCity?: string;
    isPlaceholder?: boolean;
    team: TeamWithContacts;
    teamSize: number;
    index: number;
    isTeamOver: boolean;
    teamFullness: number;
    moveChip: (chip: TeamRowItem, groupFrom: TeamWithContacts, draggedIndex: number, groupTo: TeamWithContacts, hoverIndex: number) => void;
    dropToTeam: (team: TeamWithContacts, golferId: string, index: number) => void;
    onDelete: () => void;
    onOpen: (id: string) => void;
}

interface TeamContactItemSourceProps {
    connectDragSource: ConnectDragSource;
    connectDragPreview: ConnectDragPreview;
    connectDropTarget: ConnectDropTarget;
    isDragging: boolean;
    canDrop: boolean;
    item: TeamContactItemProps;
}

interface TargetProps {
    connectDropTarget: ConnectDropTarget;
    canDrop: boolean;
    isOver: boolean;
    item: TeamContactItemProps;
}

const contactSource = {
    beginDrag(props: TeamContactItemProps) {
        return props;
    },
};

const contactTarget = {
    hover(props: TeamContactItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as TeamContactItemProps;
        if (item.team.team.id === props.team.team.id) {
            const chip: TeamRowItem = { id: item.id, name: item.name, isPlaceholder: false, hidden: false, version: -1 }
            item.moveChip(chip, item.team, item.index, props.team, props.index);
        } else {
            const chip: TeamRowItem = { id: item.id, name: item.name, isPlaceholder: true, hidden: false, version: -1 }
            props.moveChip(chip, item.team, item.index, props.team, props.index);
        }
    },
    canDrop(props: TeamContactItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as TeamContactItemProps;
        return props.teamFullness < 1 || (!!props.team.contacts.find(c => c.id === item.id) || !!props.team.contacts.find(t => t.id === item.id));
    },
    drop(props: TeamContactItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as TeamContactItemProps;
        if (item.team.team.id === props.team.team.id) {
            item.dropToTeam(props.team, item.id, props.index);
        } else {
            props.dropToTeam(props.team, item.id, props.index);
        }
    }
};

const teamTarget = {
    canDrop(props: TeamItemProps, monitor: DropTargetMonitor) {
        const item = monitor.getItem() as TeamContactItemProps;
        return monitor.isOver() && (props.team.contacts.length < props.teamSize + 1 || !!props.team.contacts.find(c => c.id === item.id));
    },
    drop(props: TeamItemProps, monitor: DropTargetMonitor) {
        if (!monitor.didDrop()) {
            const item = monitor.getItem() as TeamContactItemProps;
            props.dropToTeam(item.id);
        }
    }
};

const collectSource = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
});

const collectContactTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
    item: monitor.getItem() as TeamContactItemProps,
});

const collectTeamTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
    item: monitor.getItem() as TeamContactItemProps,
});

const TeamContactItemDnD = React.forwardRef<HTMLDivElement, TeamContactItemProps & TeamContactItemSourceProps & TargetProps & WithStyles<typeof styles>>(
    ({ id, name, classes, isPlaceholder, onDelete, onOpen, isOver, isTeamOver, item, connectDragSource, connectDropTarget, homeCourseOrCity }, _ref) => {
        const elementRef = useRef(null);
        connectDragSource(elementRef);
        connectDropTarget(elementRef);
        const label = <NoWrapOverflow><PersonIcon className={classes.textIcon} />{name}<span className={classes.homeCourseOrCity}>{homeCourseOrCity ? ` (${homeCourseOrCity})` : ''}</span></NoWrapOverflow>;
        return !isPlaceholder || (isTeamOver && item.id === id) ? (<span ref={elementRef} style={{ backgroundColor: 'rgba(0, 0, 0, 0)', height: '100%', display: 'inline-block', borderRadius: 16 }}>
            {<FixedWidthChip label={label} color={isOver && !!item && item.id === id ? 'secondary' : 'default'} onDelete={onDelete} onDoubleClick={() => onOpen(id)} />}
        </span>) : <span></span>;
    },
);

const TeamContactItem = withStyles(styles)(DropTarget(DRAG_TYPE_TEAM, contactTarget, collectContactTarget)(DragSource(DRAG_TYPE_TEAM, contactSource, collectSource)(TeamContactItemDnD)));

function gatherItems(team: TeamWithContacts, golfers: Map<string, Contact>, version: number): TeamRowItem[] {
    const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
    const items: TeamRowItem[] = [];
    team.contacts.map(contact => items.push({
        id: contact.id,
        name: fullName(contact),
        homeCourseOrCity: sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? contact.homeCourseOrCity : undefined,
        isPlaceholder: false,
        hidden: !golfers.has(contact.id),
        version
    }));
    return items;
}

const TeamItemDnD = React.forwardRef<HTMLDivElement, TeamItemProps & TargetProps & WithStyles<typeof styles>>(
    ({ initialItems, addGolferToTeamFunc, team, golfers, classes, teamSize, canDrop, isOver, item, teamFullness, addToTeam, openGolfer, deleteFromTeam, connectDropTarget }, _ref) => {
        const showIcon = teamFullness < 1 && !item;
        const [initialState, setInitialState] = useState(initialItems);
        const [items, setItems] = useState(initialState);
        if (initialState.length !== initialItems.length || (initialState.length > 0 && initialState[0].version !== initialItems[0].version)) {
            setInitialState(initialItems);
            setItems(initialItems);
        }
        const dropToTeam = (team: Team, contactId: string, index: number) =>
            addGolferToTeamFunc(golfers.get(contactId)!, team, index);
        const moveChip = useCallback(
            (chip: TeamRowItem, teamFrom: TeamWithContacts, dragIndex: number, teamTo: TeamWithContacts, hoverIndex: number) => {
                const pos = items.findIndex(i => i.id === chip.id);
                if (teamFrom.team.id === teamTo.team.id) {
                    setItems(update(items, { $splice: [[dragIndex, 1], [hoverIndex, 0, chip]] }));
                } else if (teamFullness < 1) {
                    if (items.length === 0 || pos < 0) {
                        setItems(update(items, { $splice: [[hoverIndex, 0, chip]] }));
                    } else {
                        setItems(update(items, { $splice: [[pos, 1], [hoverIndex, 0, chip]] }));
                    }
                }
            },
            [items, teamFullness]);
        const contactsList = items.filter(i => !i.hidden).map((item: TeamRowItem, i: number) => <TeamContactItem
            id={item.id}
            key={item.id}
            index={i}
            name={item.name}
            team={team}
            teamSize={teamSize}
            isPlaceholder={item.isPlaceholder}
            homeCourseOrCity={item.homeCourseOrCity ? item.homeCourseOrCity : undefined}
            teamFullness={teamFullness}
            isTeamOver={isOver}
            moveChip={moveChip}
            onOpen={openGolfer}
            onDelete={() => deleteFromTeam(item.id)}
            dropToTeam={(team: TeamWithContacts, contactId: string, index: number) => dropToTeam(team.team, contactId, index)} />);
        if (!!item && canDrop && isOver && item.team.team.id !== team.team.id && items.findIndex(i => i.id === item.id) < 0) {
            const label = <NoWrapOverflow><PersonIcon className={classes.textIcon} />{item.name}</NoWrapOverflow>;
            contactsList.push((<FixedWidthChip key={item.id + '_placeholder'} label={label} onDelete={() => { }} className={classes.dragActive} onDoubleClick={() => { }} />));
        }
        const decorations = classes.listItem;
        return connectDropTarget(
            <div>
                <ListItem className={decorations}>
                    <Container wrap="nowrap">
                        <Item height={38}>
                            <Typography variant="body2" noWrap style={{ width: 64 }}>{teamName(team.team)}</Typography>
                            {team.contacts.length > teamSize && <span style={{ color: 'red', fontSize: '0.65rem' }}>{Utils.withS(team.contacts.length, 'golfer')}</span>}
                        </Item>
                        <Item wrapLabel>
                            {contactsList}
                            {showIcon && <IconButton
                                className={classes.smallIconButton}
                                onClick={() => addToTeam(team)}
                                size="large"><AddCircleOutlineIcon /></IconButton>}
                        </Item>
                    </Container>
                </ListItem >
                <Divider />
            </div>
        );
    });

const TeamItem = withStyles(styles)(DropTarget(DRAG_TYPE_TEAM, teamTarget, collectTeamTarget)(TeamItemDnD));

interface State {
    editingTeam: number;
    editedContact?: ContactDetails;
    doAutoPair: boolean;
    openTeamSizeDialog?: boolean;
    handleTeamExport: boolean;
}

type Props = { event: Event; eventData: EventData; } & WithStyles<typeof styles> & WithUserId;

class TeamsList extends React.Component<Props, State> {
    state: State = {
        editingTeam: -1,
        doAutoPair: false,
        handleTeamExport: false,
    };

    componentDidMount() {
        Backend.trackEvent('view_teams');
    }

    private handleGolferRowClick = (contact?: ContactDetails) => contact && this.setState({ editedContact: { ...contact } });
    private handleContactDeleted = (golfers: Array<Contact>) => this.deleteGolfersFromEvent(golfers);
    private handleCloseEditDialog = () => this.setState({ editedContact: undefined });
    private handleTeamExport = () => this.setState({ handleTeamExport: true });
    private hideTeamsSizeDialog = () => this.setState({ openTeamSizeDialog: false });
    private showTeamsSizeDialog = () => this.setState({ openTeamSizeDialog: true });

    private eventOrRound() {
        const { event } = this.props;
        const { rounds, selectedRound } = this.props.eventData;
        return (event.type === 'multiday' && SAME_TEAMS_IN_ALL_ROUNDS ? rounds[0] : selectedRound) ?? event;
    }

    private getStaff() {
        const { event } = this.props;
        const { rounds, golfersMap, teamsMap, teamsListMap, groupsMap, competitionsMap } = this.props.eventData;
        const eventOrRound = this.eventOrRound();
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teamsList = teamsListMap.get(eventOrRound.id) ?? [];
        const competitions = competitionsMap.get(this.eventOrRound().id) ?? [];
        return { event, rounds, eventOrRound, golfers, teams, teamsList, groups, competitions };
    }

    private handleReset = () => {
        if (SAME_TEAMS_IN_ALL_ROUNDS) {
            const { event } = this.props;
            const { rounds } = this.props.eventData;
            withProgress(resetEventTeams(event, rounds));
        } else {
            withProgress(resetEventTeams(this.eventOrRound()));
        }
    }

    private deleteGolfersFromEvent(golfersToDelete: Array<Contact>) {
        const { event } = this.props;
        withProgress(deleteGolfersFromEvent(event, golfersToDelete, true));
    }

    private handleContactChanged = (contactDetail: Contact, notificationLess: boolean) => {
        const { event } = this.props;
        const { roster } = this.props.eventData;
        roster.set(contactDetail.id, contactDetail)
        saveContact(event, contactDetail, contactDetail.id ? 'Golfer modified' : 'Golfer added', notificationLess)
            .then(() => this.setState({ editedContact: undefined }));
    }

    private saveTeams = async (teams: Array<Team>, teamsToDel: Array<Team>, genderMode: GenderMode, handicapMode: HandicapMode) => {
        const { event } = this.props;
        if (SAME_TEAMS_IN_ALL_ROUNDS) {
            const { rounds } = this.props.eventData;
            await withProgress(saveEventPairedTeams(event, rounds, teams, teamsToDel));
        } else {
            await withProgress(saveEventPairedTeams(this.eventOrRound(), [], teams, teamsToDel));
        }
        elog(event, `Teams auto pairing`, `With handicapMode: ${handicapMode} genderMode: ${genderMode}. ${Utils.withS(teams.length, 'new team')} have been paired.`, '');
    }

    private pairTeams = (genderMode: GenderMode, handicapMode: HandicapMode, keepTeams: boolean) => {
        const { eventOrRound, golfers, groups, teamsList } = this.getStaff();
        const visibleGolfers = new Map<string, Contact>();
        golfers.forEach(g => {
            if (!g.hidden) {
                visibleGolfers.set(g.id, g);
            }
        });
        const count = Math.ceil(visibleGolfers.size / eventOrRound.teamSize);
        const teamsFrom = keepTeams ? teamsList : [{ id: '', contactIds: [], order: 0 }];
        const newTeams = makeNewGroups(teamsFrom, eventOrRound.teamSize, count, true, Array.from(golfersOrTeams(visibleGolfers, teamsFrom, true).values()), handicapMode, genderMode);
        const teamsToDel = keepTeams ? [] : teamsList.filter(t1 => t1.id && !newTeams.find(t2 => t2.id === t1.id));
        const autoScheduledGroups = makeNewGroups([], golfersOrTeamsPerGroup(eventOrRound), 0, false, newTeams, 'random', 'random');
        const groupsToDelete = groups.filter(group => group.id && !autoScheduledGroups.find(other => other.id === group.id));
        this.saveTeams(newTeams, teamsToDel, genderMode, handicapMode)
            .then(() => saveGroupsBatch(autoScheduledGroups, genderMode, handicapMode, groupsToDelete, eventOrRound));
    }

    private handlePairTeams = (genderMode: GenderMode, handicapMode: HandicapMode, keepTeams: boolean) =>
        this.setState({ doAutoPair: false }, () => this.pairTeams(genderMode, handicapMode, keepTeams));

    private teamWithContacts(team: Team): TeamWithContacts {
        const { golfers } = this.getStaff();
        return { team: team, contacts: visibleTeamContacts(team, golfers) };
    }

    private saveTeamSize = (teamSize: ScoringTeamSize, oldTeamSize: ScoringTeamSize) => {
        const { event } = this.props;
        const { rounds } = this.props.eventData;
        withProgress(setEventTeamSize(event, rounds, teamSize, oldTeamSize))
            .then(() => this.setState({ openTeamSizeDialog: false }));
    }

    private exportTeamData() {
        const { eventOrRound, golfers, teamsList } = this.getStaff();
        const exportData: string[][] = [];
        const exportHeader = ['Team'];
        for (let i = 1; i <= eventOrRound.teamSize; i += 1) {
            exportHeader.push('Golfer ' + i);
        }
        exportData.push(exportHeader);
        teamsList.forEach((team, index) => {
            const teamContacts = visibleTeamContacts(team, golfers)
            if (teamContacts.length > 0) {
                const exportRow: string[] = [];
                exportRow.push(String(index + 1));
                teamContacts.forEach(contact => exportRow.push(fullName(contact)));
                exportData.push(exportRow);
            }
        });
        return exportData;
    }

    private unpairedGolfers = (eventOrRound: EventBase) => {
        if (eventOrRound.teamSize === 1) {
            return 0;
        }
        const { golfersMap, teamsMap, loadedTeams, loadedGolfers } = this.props.eventData;
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        return loadedTeams > 0 && loadedGolfers > 0 ? getUnpairedGolfersCount(teams, golfers) : 0;
    }

    private deleteFromTeam = (contactId: string) => {
        const { golfers } = this.getStaff();
        const golferToRemove = golfers.get(contactId);
        if (!golferToRemove) {
            return;
        }
        if (SAME_TEAMS_IN_ALL_ROUNDS) {
            const { event } = this.props;
            const { rounds } = this.props.eventData;
            withProgress(removeGolferFromTeam(event, rounds, golferToRemove));
        } else {
            withProgress(removeGolferFromTeam(this.eventOrRound(), [], golferToRemove));
        }
    }

    private addGolferToTeam = (golfer: Contact, toTeam: Team, positionInTeam?: number) => {
        const { event, rounds, eventOrRound } = this.getStaff();
        if (SAME_TEAMS_IN_ALL_ROUNDS) {
            withProgress(addGolferToTeam(event, rounds, golfer, toTeam, positionInTeam))
        } else {
            withProgress(addGolferToTeam(eventOrRound, [], golfer, toTeam, positionInTeam));
        }
    }

    private Teams = () => {
        const { classes, event } = this.props;
        const { rounds, selectedRound, setSelectedRound, loadedTeams, loadedGolfers } = this.props.eventData;
        const { handleTeamExport } = this.state;
        const { eventOrRound, golfers, teamsList } = this.getStaff();
        const moveToTeam = (team: Team, contactId: string) => this.addGolferToTeam(golfers.get(contactId)!, team);
        const fileName = event.name.replace(' ', '-') + '-' + Utils.formatDateDashed2(event.date) + (selectedRound ? '-round' + selectedRound.roundOrder : '');
        const exportFile = `${fileName}-teams.csv`;
        const exportData = handleTeamExport ? this.exportTeamData() : '';
        const unpairedGolfers = this.unpairedGolfers(eventOrRound);
        return <div className={classes.listRootGrey}>
            {(event.type === 'multiday' && !SAME_TEAMS_IN_ALL_ROUNDS) && <ButtonBar margin>
                {rounds.map(round => <AppButton
                    key={round.roundOrder}
                    className={classes.eventRoundButton}
                    color={round.roundOrder === selectedRound?.roundOrder ? 'primary' : 'info'}
                    sx={{ backgroundColor: round.roundOrder === selectedRound?.roundOrder ? undefined : AppColors.white }}
                    onClick={() => setSelectedRound(round)} >
                    Round {round.roundOrder}
                    <ButtonBadge
                        invisible={this.unpairedGolfers(round) === 0}
                        selected={round.roundOrder === selectedRound?.roundOrder} />
                </AppButton>)}
            </ButtonBar>}
            <List disablePadding className={SAME_TEAMS_IN_ALL_ROUNDS ? classes.listRootGrey : classes.listRootGreySmallVert}>
                <LabeledField label={'Teams'} itemClass={classes.listItem} value={teamSizeName(eventOrRound.teamSize)} edit={this.showTeamsSizeDialog} />
            </List>
            {eventOrRound.teamSize > 1 && <List disablePadding className={SAME_TEAMS_IN_ALL_ROUNDS ? classes.listRootGrey : classes.listRootGreySmallVert}>
                <ListItem className={classes.listItem}>
                    <ButtonBar margin>
                        <AppButton color="secondary" onClick={() => this.setState({ doAutoPair: true })}>
                            <PeopleIcon className={classes.leftButtonIcon} />Auto pair...
                        </AppButton>
                        {<CSVLink data={exportData} filename={exportFile} style={{ textDecoration: 'none' }}>
                            <AppButton color="info" onClick={this.handleTeamExport}>Export</AppButton>
                        </CSVLink>}
                        <AppButton color="info" onClick={this.handleReset}>Reset all</AppButton>
                        {unpairedGolfers > 0 && <Typography variant="body1" style={{ marginLeft: 16 }}>
                            {Utils.withS(unpairedGolfers, 'golfer')} not paired yet
                            <Badge sx={{ marginBottom: 2, marginLeft: 1 }} color="error" variant="dot" overlap="rectangular" />
                        </Typography>}
                    </ButtonBar>
                </ListItem>
                {teamsList.map((team, idx) => {
                    const teamWithContacts = this.teamWithContacts(team);
                    return <TeamItem
                        key={idx}
                        addGolferToTeamFunc={this.addGolferToTeam}
                        golfers={golfers}
                        team={teamWithContacts}
                        teamSize={eventOrRound.teamSize}
                        teamFullness={visibleTeamContactIds(team, golfers).length - eventOrRound.teamSize}
                        initialItems={gatherItems(teamWithContacts, golfers, loadedTeams * 10000 + loadedGolfers)}
                        addToTeam={team => this.setState({ editingTeam: team.team.order })}
                        dropToTeam={(contactId: string) => moveToTeam(team, contactId)}
                        openGolfer={id => this.handleGolferRowClick(golfers.get(id))}
                        deleteFromTeam={this.deleteFromTeam} />
                })}
            </List>}
        </div>;
    }

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { event, eventData } = this.props;
        await sendPaymentMessage(contactsToSend, event, eventData, new Map<string, Score>(), new Map<string, Score>(),
            new Map<string, ReportedScore>(), new Map<string, ReportedScore>(), new Map<string, Distance>(), variant);
    };

    render() {
        const { event, eventData } = this.props;
        const { rounds, loadedTeams, loadedGolfers } = eventData;
        const { eventOrRound, golfers, teamsList, competitions } = this.getStaff();
        const { editedContact, doAutoPair, openTeamSizeDialog, editingTeam } = this.state;
        return <>
            <this.Teams />
            {!!editedContact && <EditContactDialog
                open
                event={event}
                rounds={rounds}
                actionMode={editedContact.id ? 'edit' : 'add'}
                initialContact={editedContact}
                saveToEvent={this.handleContactChanged}
                handleClose={this.handleCloseEditDialog}
                sendEmail={this.handleSendMessage}
                deleteFromEvent={this.handleContactDeleted} />}
            {editingTeam >= 0 && <EditTeamsDialog
                event={event}
                rounds={rounds}
                eventOrRound={eventOrRound}
                golfers={golfers}
                teamsList={teamsList}
                loadedTeams={loadedTeams}
                loadedGolfers={loadedGolfers}
                editingTeam={editingTeam}
                handleClose={() => this.setState({ editingTeam: -1 })} />}
            {doAutoPair && <AutoScheduleDialog
                open
                pairing
                eventOrRound={eventOrRound}
                close={() => this.setState({ doAutoPair: false })}
                save={this.handlePairTeams}
                golfersOrTeams={golfersOrTeams(golfers, teamsList, true)}
                groups={teamsList}
                count={Math.ceil(golfers.size / eventOrRound.teamSize)}
                groupSize={eventOrRound.teamSize} />}
            {openTeamSizeDialog && <SelectTeamSizeDialog
                teamSize={eventOrRound.teamSize}
                saveTeamSize={this.saveTeamSize}
                close={this.hideTeamsSizeDialog}
                competitions={competitions} />}
        </>;
    }
}

export default withStyles(styles)(withUserId(TeamsList));
