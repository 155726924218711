import * as React from 'react';
import { DialogActions, DialogContent, Typography, FormControl, Grid, ListItem, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as AppTheme from '../../main/Theme';
import * as Utils from '../../util/utility';
import * as Backend from '../../util/firebase';
import * as Scoring from '../../scoring/scoring';
import { WithUserId, withUserId } from '../../auth/Auth';
import { EVENT_DATETIME_FORMAT, Urls } from '../../util/config';
import { Event, Competition, getEventMainCompetition, MAX_GOLFERS_PRO, MAX_GOLFERS, Round, getCourseNameExt } from '../../types/EventTypes';
import { LinkIcon, DoneIcon, LocationIcon, ScoringIcon, GolferAddIcon } from '../../common/Icons';
import { SMMobileDialog } from '../../common/dialog/MobileDialog';
import AppButton from '../../common/components/AppButton';
import ListGridItem from '../../common/components/ListGridItem';
import DialogAppBar from '../../common/dialog/DialogAppBar';
import CopyTextField from '../../common/components/CopyTextField';
import { showProgress } from '../../redux/ReduxConfig';
import { getRegistrationDate, formatDateUniversal, formatTeesName } from "../Event";
import CurrencyList from 'currency-list';

const dlgStyles = (_: Theme) => {
    return {
        ball: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            height: 20,
            width: 20,
            minHeight: 20,
            minWidth: 20,
            marginRight: 10,
            backgroundColor: AppTheme.AppColors.webBlue300
        },
        title: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        titleSmall: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 20
        }
    }
};

type NextStepsGridProps = {
    handleClose: (variant: 'settings/portal' | 'golfers' | '') => void;
};

const NextStepsGrid = ({ handleClose }: NextStepsGridProps) => {
    const buttons = <>
        <AppButton onClick={() => handleClose('settings/portal')} color="info" style={{ minWidth: 100 }}>
            Edit portal
        </AppButton>
        <AppButton onClick={() => handleClose('golfers')} color="secondary" style={{ marginLeft: 7, minWidth: 100 }}>
            Add golfers
        </AppButton>
    </>;
    const textCustomize =
        <Typography sx={AppTheme.bottomTextStyle} variant="caption">
            Customize your tournament portal images and content, or add golfers to the event.
        </Typography>;
    const isXs = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 14, lineHeight: '21px', color: AppTheme.AppColors.webBlack, fontWeight: 500 }}>
                    Next steps
                </Typography>
            </Grid>
            <Grid item>
                {isXs ? <Grid container direction="column" rowSpacing={1}>
                    <Grid item xs={12}>
                        {textCustomize}
                    </Grid>
                    <Grid item xs={12}>
                        {buttons}
                    </Grid>
                </Grid> : <Grid container direction="row">
                    <Grid item xs={7}>
                        {textCustomize}
                    </Grid>
                    <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
                        {buttons}
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>);
};

type Props = {
    event: Event;
    handleClose: (variant: 'settings/portal' | 'golfers' | '') => void;
} & WithStyles<typeof dlgStyles> & WithUserId;

interface State {
    rounds: Array<Round>;
    competitions: Array<Competition>;
}

class EventCreatedDialog extends React.Component<Props, State> {
    private mounted = false;

    state: State = {
        rounds: [],
        competitions: []
    };

    componentDidMount() {
        this.mounted = true;
        this.loadInfo();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private loadInfo = async () => {
        const { event } = this.props;
        const hideProgress = showProgress();
        try {
            let rounds = new Array<Round>();
            let competitions = new Array<Competition>();
            if (event.type === 'multiday') {
                rounds = await Backend.getEntities<Round>(Backend.eventRoundsQuery(event.id));
                if (!this.mounted) return;
            }
            if (event.type !== 'multiday' || rounds.length > 0) {
                competitions = await Backend.getEntities<Competition>(Backend.eventCompetitionsQuery((event.type === 'multiday' ? rounds[0] : event).id));
                if (!this.mounted) return;
            }
            this.setState({ rounds, competitions });
        } finally {
            hideProgress();
        }
    };

    render() {
        const { classes, event, pro, handleClose } = this.props;
        const { rounds, competitions } = this.state;
        const multiday = event.type === 'multiday';
        const mainCompetition = getEventMainCompetition(competitions);
        const teesName = formatTeesName(rounds[0] ?? event);
        const competitionName = mainCompetition ? Scoring.scoringName(mainCompetition, event.eventGender, mainCompetition.competitionGender) : '';
        const absMaxGolfers = pro ? MAX_GOLFERS_PRO : MAX_GOLFERS;
        const registrationLink = Urls.makeAboutLink(event.publicId);
        const textRegistrationClosed = 'Only event administrator can add golfers';
        const textRegistrationOpen = `Registration open to anyone with a link${event.paymentSettings?.feeCost ?
            ` (${CurrencyList.get(event.paymentSettings.currencyCode).symbol}${event.paymentSettings.feeCost} fee)` : ''}, ${event.maxGolfers || absMaxGolfers} spots`;
        const textRegistrationAdmin = 'Only you as an admin can add golfers';
        const textDeadlineThrough = `Deadline through ${formatDateUniversal(getRegistrationDate(event),
            'D MMMM YYYY, dddd')}`;
        const courseName = getCourseNameExt(event, rounds);
        const label =
            <div className={classes.title}>
                <span className={classes.ball}><DoneIcon color={AppTheme.AppColors.primaryTextColor} /></span>
                {multiday ? 'Multi-day Event created' : 'Event created'}
            </div>;
        return (
            <SMMobileDialog open={true} onClose={() => handleClose('')} maxWidth={'md'} fullWidth>
                <DialogAppBar bar close={() => handleClose('')} label={label} toolbarBarStyle={{ padding: 0, marginLeft: 16, marginRight: 16 }} />
                <DialogAppBar>
                    <span className={classes.titleSmall}>You can modify event details in Settings</span>
                </DialogAppBar>
                <DialogContent>
                    <Typography sx={{ fontSize: 20, fontWeight: 500 }}>{event.name}</Typography>
                    {multiday && <Typography sx={{ fontSize: 14 }}>{Utils.withS(rounds.length, 'Round')}</Typography>}
                    <FormControl variant="standard" margin="dense" fullWidth>
                        <ListGridItem
                            icon={<DateRangeIcon color="primary" />}
                            sx1={AppTheme.topTextStyle}
                            sx2={AppTheme.bottomTextStyle}
                            text1={formatDateUniversal(event.date, EVENT_DATETIME_FORMAT)}
                            text2={Utils.formatDayOfWeek(event.date)} />
                        <ListGridItem
                            icon={<LocationIcon color="primary" />}
                            sx1={AppTheme.topTextStyle}
                            sx2={AppTheme.bottomTextStyle}
                            text1={courseName}
                            text2={teesName} />
                        <ListGridItem
                            icon={<ScoringIcon color="primary" />}
                            sx1={AppTheme.topTextStyle}
                            sx2={AppTheme.bottomTextStyle}
                            text1={competitionName}
                            text2={event.teamSize > 1 ? 'Team' : 'Individual'} />
                        <ListGridItem
                            icon={<GolferAddIcon color="primary" />}
                            sx1={AppTheme.topTextStyle}
                            sx2={AppTheme.bottomTextStyle}
                            text1={event.registrationByAdmin ? textRegistrationClosed : textRegistrationOpen}
                            text2={event.registrationByAdmin ? textRegistrationAdmin : textDeadlineThrough} />
                        {!event.registrationByAdmin && <>
                            <ListItem>
                                <CopyTextField style={{ paddingLeft: 32 }} readOnly btnOnly
                                    color="secondary"
                                    fieldValue={registrationLink}
                                    btnLabel={<React.Fragment>Copy link<LinkIcon /></React.Fragment>}
                                />
                                <AppButton onClick={() => window.open(`${window.location.origin}/event/${event.publicId}`, '_blank')} color="secondary"
                                    style={{ marginLeft: 7, minWidth: 100 }}>View registration page <ArrowForwardIcon /></AppButton>
                            </ListItem>
                        </>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <ListItem style={{ width: '100%', paddingLeft: 30 }}>
                        <NextStepsGrid handleClose={handleClose} />
                    </ListItem>
                </DialogActions>
            </SMMobileDialog>
        );
    }
}

export default withStyles(dlgStyles)(withUserId(EventCreatedDialog));
