import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'typeface-poppins';
import 'typeface-roboto';
import App from './main/App';
import './index.css';

ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
);
