import { EventBase, Event, Contact, ContactDetails, Gender, Team, ContactInvite, Tee } from '../types/EventTypes';

import * as Utils from '../util/utility';

export function contactInfo(contact: Contact) {
    return `${contact.firstName} ${contact.lastName}, ${contact.gender}, ${contact.handicapIndex}`;
}

export function hasContactTees(contact: ContactDetails) {
    if (contact.tee) {
        return true;
    }
    if (contact.roundTees) {
        for (const p in contact.roundTees) {
            if (contact.roundTees[p]) {
                return true;
            }
        }
    }
    return false;
}

export function getContactTees(contact: ContactDetails, eventOrRounds: Array<EventBase>): Array<Tee | undefined> {
    return eventOrRounds.map(eventOrRound => (contact.roundTees ?? {})[eventOrRound.id]);
}

export function contactFromRoaster(contact: ContactDetails) {
    const returnContact: ContactDetails = { ...contact };
    delete returnContact.reportedBy;
    delete returnContact.tee;
    delete returnContact.roundTees;
    delete returnContact.withdrawn;
    delete returnContact.disqualified;
    return returnContact;
}

export function contactFrom(golfer: Contact, eventOrRounds: Array<EventBase>, golfersMap: Map<string, Map<string, Contact>>, contact?: ContactDetails) {
    const roundTees: { [s: string]: Tee; } = {};
    eventOrRounds.forEach(eventOrRound => {
        const golfers = golfersMap.get(eventOrRound.id);
        if (golfers && golfers.has(golfer.id)) {
            const golferX = golfers.get(golfer.id)!;
            if (golferX.tee) {
                roundTees[eventOrRound.id] = golferX.tee;
            }
        }
    });
    const currentContact: ContactDetails = { ...golfer, roundTees };
    if (contact) {
        currentContact.email = contact.email;
        currentContact.phone = contact.phone;
        currentContact.notes = contact.notes;
    }
    return currentContact;
}

export function inviteFrom(invite: ContactInvite, roster?: ContactDetails) {
    const currentInvite: ContactInvite = {
        ...invite
    };
    if (roster) {
        if (!!roster.email) {
            currentInvite.email = roster.email;
        }
        currentInvite.firstName = roster.firstName;
        currentInvite.lastName = roster.lastName;
    }
    return currentInvite;
}

export function toContact(contactDetails: ContactDetails): Contact {
    return {
        id: contactDetails.id,
        firstName: contactDetails.firstName,
        lastName: contactDetails.lastName,
        avatar: contactDetails.avatar,
        handicapIndex: contactDetails.handicapIndex,
        gender: contactDetails.gender,
        hidden: contactDetails.hidden,
    };
}

export function changeHiddeness(contact: Contact, reveal?: boolean): Contact {
    contact.hidden = !reveal;
    return contact;
}

export function contactName(contact: Contact, comma?: boolean, handicap?: boolean, cutFirstTo?: number, cutLastTo?: number) {
    let first = '';
    if (contact.firstName) {
        first = !!cutFirstTo && contact.firstName.length > cutFirstTo ? contact.firstName.substring(0, cutFirstTo) + '...' : contact.firstName;
        first = comma ? (', ' + first) : (first + ' ');
    }
    const handicapTitle = handicap ? ' (' + formatHandicap(contact.handicapIndex) + ')' : '';
    const last = !!cutLastTo && contact.lastName.length > cutLastTo ? contact.lastName.substring(0, cutLastTo) + '...' : contact.lastName;
    return (comma ? (last + first) : (first + last)) + handicapTitle;
}

export function contactInitials(contact: Contact, extend?: boolean) {
    let first = '';
    if (contact.firstName) {
        if (extend) {
            first = (contact.firstName.length > 7 ? contact.firstName.substring(0, 7) + '...' : contact.firstName) + ' ';
        } else {
            first = contact.firstName.charAt(0).toUpperCase() + '. ';
        }
    }
    return first + contact.lastName.charAt(0).toUpperCase() + '.';
}

export function fullName(contact: Contact, cutFirstTo?: number, cutLastTo?: number) {
    return contactName(contact, false, false, cutFirstTo, cutLastTo);
}

export function fullLastName(contact: Contact) {
    return contactName(contact, true, false);
}

export function emailOrName(contact: Contact) {
    let name = '';
    if (contact.firstName) {
        name = contact.firstName + ' ';
    }
    if (contact.lastName && contact.lastName.length > 0) {
        name += contact.lastName + ' ';
    }
    return name.length > 0 ? `${name}` : `${contact.email}`;

}

export function fullInviteName(invite: ContactInvite) {
    if (invite.firstName || invite.lastName) {
        return `${invite.firstName ?? ''} ${invite.lastName ?? ''} (${invite.email})`;
    }
    return invite.email;
}

export function shortName(contact: Contact, handicap?: boolean, cutLastTo?: number) {
    if (!contact) {
        return '';
    }
    let first = '';
    if (contact.firstName && contact.firstName.length > 0) {
        first = ' ' + contact.firstName.charAt(0).toUpperCase() + '.';
    }
    const handicapTitle = handicap ? ' (' + formatHandicap(contact.handicapIndex) + ')' : '';
    const last = !!cutLastTo && contact.lastName.length > cutLastTo ? contact.lastName.substring(0, cutLastTo) + '..' : contact.lastName;
    return last + first + handicapTitle;
}

export function shortSurname(contact: Contact) {
    if (!contact) {
        return '';
    }
    const lastName = contact.firstName ? contact.lastName.substring(0, 1).toUpperCase() : contact.lastName;
    return `${contact.firstName} ${lastName}`;
}

export function compareByNamesOrCity(first: Contact, second: Contact): number {
    let comparisonResult = first.lastName.localeCompare(second.lastName);
    if (!comparisonResult && (first.firstName || second.firstName)) {
        comparisonResult = first.firstName && !second.firstName ? first.firstName.localeCompare(second.lastName) :
            !first.firstName && second.firstName ? first.lastName.localeCompare(second.firstName) :
                first.firstName!.localeCompare(second.firstName!);
    }
    if (!comparisonResult && (first.homeCourseOrCity || second.homeCourseOrCity)) {
        comparisonResult = (first.homeCourseOrCity || '').localeCompare((second.homeCourseOrCity || ''));
    }
    return comparisonResult;
}

export function compareContacts(a: Contact, b: Contact) {
    return fullName(a).localeCompare(fullName(b));
}

export function compareContactsLastName(a: Contact, b: Contact) {
    return fullLastName(a).localeCompare(fullLastName(b));
}

export function compareContactsEmail(a: Contact, b: Contact) {
    return (a.email || '').localeCompare(b.email || '');
}

export function compareInvitesEmail(a: ContactInvite, b: ContactInvite) {
    return (a.email || '').localeCompare(b.email || '');
}

export function compareInvitesStatus(a: ContactInvite, b: ContactInvite) {
    return (a.inviteStatus || '').localeCompare(b.inviteStatus || '');
}

export function compareInvites(a: ContactInvite, b: ContactInvite) {
    return fullInviteName(a).localeCompare(fullInviteName(b));
}

export function trimmedNameLastName(contact: Contact) {
    return ((contact.lastName || '') + (contact.firstName || '')).replace(/\s/g, '').toLocaleLowerCase();
}

export function fullComparisonContactName(contact: Contact) {
    return ((contact.firstName || '') + (contact.lastName || '')).replace(/\s/g, '').toLocaleLowerCase();
}

export function compareContactsSame(a: Contact, b: Contact) {
    return trimmedNameLastName(a) === trimmedNameLastName(b);
}

export function compareGolfersByTee(a: Contact, b: Contact) {
    return (a.tee?.name || '').localeCompare(b.tee?.name || '');
}

export function compareGolfersByHandicap(golferA?: Contact, golferB?: Contact) {
    const handicapA = golferA?.handicapIndex || 0;
    const handicapB = golferB?.handicapIndex || 0;
    return handicapA - handicapB;
}

export function compareContactsBy(a: Contact, b: Contact, field: string, asc: boolean) {
    switch (field) {
        case 'email': return asc ? compareContactsEmail(a, b) : -compareContactsEmail(a, b);
        case 'index': return asc ? compareGolfersByHandicap(a, b) : -compareGolfersByHandicap(a, b);
        case 'tee': return asc ? compareGolfersByTee(a, b) : -compareGolfersByTee(a, b);
        default: return asc ? compareContactsLastName(a, b) : -compareContactsLastName(a, b);
    }
}

export function compareContactInvitesBy(a: ContactInvite, b: ContactInvite, field: string, asc: boolean) {
    switch (field) {
        case 'email': return asc ? compareInvitesEmail(a, b) : -compareInvitesEmail(a, b);
        case 'status': return asc ? compareInvitesStatus(a, b) : -compareInvitesStatus(a, b);
        case 'inviteDate': return asc ? b.inviteDate - a.inviteDate : a.inviteDate - b.inviteDate;
        case 'statusDate': return asc ? b.statusDate - a.statusDate : a.statusDate - b.statusDate;
        default: return asc ? compareInvites(a, b) : -compareInvites(a, b);
    }
}

export function equalContactTees(ca: ContactDetails, cb: ContactDetails) {
    if (ca.roundTees === cb.roundTees) {
        return true;
    }
    if (!ca.roundTees) {
        return !cb.roundTees;
    }
    if (!cb.roundTees) {
        return false;
    }
    const a = Object.entries<Tee>(ca.roundTees);
    const b = Object.entries<Tee>(cb.roundTees);
    for (const roundTee of a) {
        const idx = b.findIndex(elem => elem[0] === roundTee[0]);
        if (idx >= 0) {
            const atee = roundTee[1];
            const btee = b[idx][1];
            if (atee?.id !== btee?.id) {
                return false;
            }
            b.splice(idx, 1);
        } else {
            return false;
        }
    }
    if (b.length > 0) {
        return false;
    }
    return true;
}

export function equalContactData(a: ContactDetails, b: ContactDetails) {
    return a.handicapId === b.handicapId &&
        a.handicapIndex === b.handicapIndex &&
        a.gender === b.gender && a.hidden === b.hidden && a.email === b.email &&
        equalContactTees(a, b);
}

export function indexOfContact(golfers: Array<Contact>, id: string) {
    for (let i = 0; i < golfers.length; i++) {
        if (golfers[i].id === id) {
            return i;
        }
    }
    return -1;
}

export function newContact(event: Event, invite?: ContactInvite): ContactDetails {
    const gender = event ? (event.eventGender === 'men' ? 'male' : event.eventGender === 'women' ? 'female' : '') : '';
    return {
        id: invite?.id ?? '',
        firstName: invite?.firstName,
        lastName: invite?.lastName ?? '',
        email: invite?.email,
        gender,
        roundTees: {},
        hidden: false
    };
}

export function newInvite(): ContactInvite {
    return {
        id: '',
        email: '',
        hidden: false,
        inviteStatus: 'invite_sent',
        inviteDate: Date.now(),
        statusDate: Date.now()
    };
}

export function formatHandicap(value?: number) {
    if (value == null) {
        return;
    }
    // const val = value.toFixed(1);
    const r = Utils.round(value, 1);
    const val = r.toString(10);
    return value < 0 ? `+${-val}` : val;
}

const MALE = ['male', 'men', 'man', 'm', 'gent', 'gentleman', 'boy', 'boys'];
const FEMALE = ['female', 'fem', 'woman', 'women', 'lady', 'ladies', 'girl', 'girls', 'f', 'w'];

export function parseGender(value?: string): Gender {
    if (!value) {
        return '';
    }
    value = value.toLowerCase();
    if (MALE.find(s => s === value)) {
        return 'male';
    }
    if (FEMALE.find(s => s === value)) {
        return 'female';
    }
    return '';
}

export function golfersOfTeam(team: Team, golfers: Map<string, Contact>): Array<Contact> {
    const teamGolfers: Array<Contact> = [];
    // do not include deleted golfers as artifacts #177771246
    // neither empty groups 
    team.contactIds?.forEach(contactId => {
        if (golfers.has(contactId) && !golfers.get(contactId)!.hidden) {
            teamGolfers.push(golfers.get(contactId)!);
        }
    });
    return teamGolfers.sort(compareGolfersByHandicap);
}

export function golferTeamFullNames(team: Team, golfers: Map<string, Contact>, sameNameGolfersIdsSet?: Set<string>) {
    return sameNameGolfersIdsSet
        ? golfersOfTeam(team, golfers).map(golfer => fullName(golfer).concat(sameNameGolfersIdsSet.has(golfer.id) && golfer.homeCourseOrCity ? ` (${golfer.homeCourseOrCity})` : ''))
        : golfersOfTeam(team, golfers).map(golfer => fullName(golfer));
}

export function golferTeamName(team: Team, golfers: Map<string, Contact>, sameNameGolfersIdsSet?: Set<string>) {
    return golferTeamFullNames(team, golfers, sameNameGolfersIdsSet && sameNameGolfersIdsSet).join(' + ');
}

export function golferShortTeamNameArray(team: Team, golfers: Map<string, Contact>, handicap?: boolean, sameNameGolfersIdsSet?: Set<string>) {
    return sameNameGolfersIdsSet
        ? golfersOfTeam(team, golfers).map(golfer => shortName(golfer, handicap, 10).concat(sameNameGolfersIdsSet.has(golfer.id) && golfer.homeCourseOrCity ? ` (${golfer.homeCourseOrCity})` : ''))
        : golfersOfTeam(team, golfers).map(golfer => shortName(golfer, handicap, 10));
}

export function golferTeamNameShort(team: Team, golfers: Map<string, Contact>, handicap?: boolean) {
    return golferShortTeamNameArray(team, golfers, handicap).join(' + ');
}

export function getSameNameGolfersIds(golfers: Array<Contact>): Set<string> {
    const resultSet = new Set<string>();
    const recurringNamesMap = new Map<string, string>();
    for (const golfer of golfers) {
        const fullComparisonName = fullComparisonContactName(golfer);
        const id = recurringNamesMap.get(fullComparisonName);
        if (id) {
            resultSet.add(id);
            resultSet.add(golfer.id);
        } else {
            recurringNamesMap.set(fullComparisonName, golfer.id);
        }
    }
    return resultSet;
}

export function getRosterContactMatcher(inputCriteria: string | undefined) {
    return (contact: ContactDetails) => {
        if (!contact.email || contact.email.length === 0) {
            return false;
        }
        const criteria = inputCriteria?.split(' ');
        const contactDetails = [contact.lastName, contact.firstName, contact.email];
        for (let i = 0; i < (criteria?.length ?? 0); i++) {
            if (contactDetails.findIndex(x => x?.toLocaleLowerCase().startsWith(criteria![i].toLocaleLowerCase())) === -1) {
                return false;
            }
        }
        return true;
    };
}
