import * as React from 'react';
import { Grid, GridSpacing, ListItem, SxProps, Typography } from '@mui/material';

export type OneToEleven = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

interface ListGridItemProps {
    icon: React.ReactNode;
    text1: string;
    sx1?: SxProps;
    text2?: string;
    sx2?: SxProps;
    spacing?: GridSpacing;
    button?: React.ReactNode;
    iconMinWidth?: string;
}

const ListGridItem = (props: ListGridItemProps) => {
    let { icon, text1, text2, button, spacing, sx1, sx2, iconMinWidth } = props;
    iconMinWidth = iconMinWidth ?? '28px';
    return (
        <ListItem disableGutters>
            <Grid container spacing={spacing ?? 1} wrap='nowrap'>
                <Grid item xs sx={{ minWidth: iconMinWidth }}>{icon}</Grid>
                <Grid item xs={button ? 7 : 11}>
                    <Typography sx={sx1}>{text1}</Typography>
                    {Boolean(text2) && <Typography sx={sx2} variant="caption">{text2}</Typography>}
                </Grid>
                {button && <span style={{ flex: '1 1 0%' }} />}
                {button && <Grid item xs={4}>{button}</Grid>}
            </Grid>
        </ListItem>
    );
};

export default ListGridItem;
