//
// Shared EVENTS types, interfaces and functions!!!
//

import * as Utils from '../util/utility';
import * as Scoring from '../scoring/scoring';

export const MAX_GOLFERS = 100;
export const MAX_GOLFERS_PRO = 1000;

export const MAX_ROUNDS = 4;

export type EventType = 'tournament' | 'leaderboard' | 'multiday' | 'round';
export type Par = 3 | 4 | 5;
export type Course = SimpleCourse | CompoundCourse;
export type ScoringFormat = ScoringFormatIndividual | ScoringFormatTeams | ScoringFormatDistance | ScoringFormatSkins | '';
export type ScoringMode = 'gross' | 'net' | 'gross+net';
export type FlightsNamingMode = 'numerical' | 'literal';
export type TeeTimeMode = 'regular' | 'shotgun';
export type Units = 'meters' | 'inches';
export type ScoringTeamSize = 1 | 2 | 3 | 4;
export type EventGender = 'both' | 'men' | 'women';
export type PurseType = 'fixed' | 'computed' | 'valuePerSkin';
export type PayoutMethod = 'carryovers' | 'divided';
export type AutoSchedule = 'ON' | 'OFF';
export type Gender = 'male' | 'female' | '';
export type ContactInviteStatus = 'sending_error' | 'invite_sent' | 'invite_resent' | 'confirmed' | 'approved' | 'declined';
export type DistanceValue = number;
export type StartingHolesType = undefined | 'hole1' | 'hole10' | 'holes1_18' | 'holes1_9' | 'holes10_18' | 'other';
export type StartingHoles = undefined | Readonly<number[]>;
export type HandicapSystem = 'WHS' | 'USGA' | 'EGA' | 'GA' | 'SAGA' | 'CONGU' | 'WHS_AU' | 'WHS_UK' | 'SMPLFD';
export type HolesRange = { first: number, last: number };
export type HandicapMode = 'random' | 'handicap' | 'mixed' | 'previous' | 'tournament';
export type GenderMode = 'random' | 'gender' | 'mix';
export type ScoringType = 'strokeplay' | 'stableford';
export type ResultStatus = 'in_progress' | 'error' | 'ok';

export const EVENT_GENDER_LABELS = ['Men and women', 'Men only', 'Women only'];
export const MAX_INVITES_COUNT = 200;
export const MAX_TEAM_SIZE = 8;
export const REGISTRATION_DEADLINE_DAYS = 3;
export const MAX_HOLES = 18;
export const NINE_HOLES = 9;
export const INCHES_FACTOR = 0.0254;
export const DEFAULT_REGISTRATION_EMAIL_NOTIFY = true;
export const DEFAULT_EVENT_PAYMENT_SETTINGS_ENABLED = false;
export const SAME_TEAMS_IN_ALL_ROUNDS = true;
export const ACTION_REGISTRATION = 'Golfer registration';
export const ACTION_ADD_FROM_ROSTER = 'Add from roster';
export const ACTION_GOLFER_MODIFIED = 'Golfer modified';
export const ACTION_GOLFER_ADDED = 'Golfer added';
export const ACTION_GOLFER_IMPORTED = 'Golfer imported';
export const ACTION_GOLFER_CONFIRMATION = 'Golfer confirmation';

export const HOLES_18 = 0;
export const HOLES_FRONT_9 = 1;
export const HOLES_BACK_9 = 2;
export const HOLES_9 = 3;
export const HOLES_9_9 = 4;
export const HOLES_NAMES = ['18 holes', 'Front nine', 'Back nine', '9 holes', '18 holes'];
export const HOLES_TYPES = [HOLES_18, HOLES_FRONT_9, HOLES_BACK_9];
export const HOLES_TYPES_COMPOUND = [HOLES_18, HOLES_9];
export const HOLES_TYPES_NINES = [HOLES_9, HOLES_9_9];
export type HolesType = 0 | 1 | 2 | 3 | 4;

export const HANDICAP_SYSTEM_NAMES = [
    'World Handicap System',
    'World Handicap System (Australia)',
    'World Handicap System (UK)',
    'Simplified - golfer index as course handicap'
];

export const CONTACT_INVITE_STATUSES = [
    'World Handicap System',
    'World Handicap System (Australia)',
    'World Handicap System (UK)',
    'Simplified - golfer index as course handicap'
];

export const HANDICAP_SYSTEM_IDS: Array<HandicapSystem> = [
    'WHS',
    'WHS_AU',
    'WHS_UK',
    'SMPLFD'
];

export const SCORING_TEAM_SIZES: Array<ScoringTeamSize> = [2, 3, 4];

export enum ScoringFormatIndividual {
    strokeplay = 'strokeplay',
    stableford = 'stableford',
    modified_stableford = 'modified_stableford'
}

export enum ScoringFormatTeams {
    best_ball = 'best_ball',
    scramble = 'scramble',
    alternate_shot = 'alternate_shot',
    chapman = 'chapman'
}

export enum ScoringFormatDistance {
    longest_drive = 'longest_drive',
    closest_to_the_pin = 'closest_to_the_pin',
}

export enum ScoringFormatSkins {
    skins_individual = 'skins_individual',
    skins_team = 'skins_team',
}

export const INDIVIDUAL_SCORINGS: Array<ScoringFormat> = [
    ScoringFormatIndividual.strokeplay,
    ScoringFormatIndividual.stableford,
];

export const INDIVIDUAL_SCORINGS_AND_SKINS: Array<ScoringFormat> = [
    ...INDIVIDUAL_SCORINGS,
    ScoringFormatSkins.skins_individual
];

export const TEAM_SCORINGS: Array<ScoringFormat> = [
    ScoringFormatTeams.best_ball,
    ScoringFormatTeams.alternate_shot,
    ScoringFormatTeams.scramble,
    ScoringFormatTeams.chapman
];

export const TEAM_SCORINGS_AND_SKINS: Array<ScoringFormat> = [
    ...TEAM_SCORINGS,
    ScoringFormatSkins.skins_team
];

export const ScoringModes: Array<ScoringMode> = [
    'gross',
    'net',
    'gross+net'
];

export interface Entity {
    id: string;
    parentId?: string;
    exists?: boolean;
}

export interface EventBase extends Entity {
    type: EventType;
    name: string;
    userId: string;
    publicId: string;
    date: number;
    dateUTC: number;
    course?: Course;
    teeMen?: Tee;
    teeWomen?: Tee;
    holesType?: HolesType;
    handicapSystem?: HandicapSystem;
    teeTime: TeeTimeSettings;
    appCompetition: Competition | null;
    teamSize: ScoringTeamSize;
    eventGender?: EventGender;
    deleted?: boolean;
    scoringSet?: boolean;
    userName?: string;
    userEmail?: string;
    badgeUrl?: string;
    bannerUrl?: string;
    roundToken?: string;
    legitDate?: number;
    legitAppDate?: number;
    lastModified?: number;
    hideLiveScores?: boolean | 'OFF' | 'ON' | 'VERIFIED';
}

export interface Round extends EventBase {
    eventId: string;
    roundOrder: number;
}

export interface Event extends EventBase {
    autoSchedule?: AutoSchedule;
    registrationByAdmin?: boolean;
    registrationEmailNotify?: boolean;
    registrationDeadline?: number;
    scorecardNotes?: string;
    scorecardDontShowTeeWarning?: boolean;
    description?: string;
    leaderboard?: boolean;
    stats?: EventStats;
    maxGolfers?: number;
    finishDate?: number;
    practiceRoundInfo?: string;
    paymentSettings?: EventPaymentSettings;
}

export interface EventMapping extends Entity {
    eventId: string;
}

export type PaymentPlatform = 'PayPal';

export interface EventPaymentSettings {
    enabled: boolean;
    feeCost: number;
    payeeEmailAddress: string;
    currencyCode: string;
    feeDescription?: string;
    platform: PaymentPlatform;
}

export interface PaymentInfo extends Entity {
    contactFirstName?: string;
    contactLastName: string;
    platformFee: number;
    feeCost: number;
    currencyCode: string;
    payeeEmailAddress: string;
    platform: PaymentPlatform;
    timeStampUTC: number;
}

export interface Score extends Entity {
    gross: Array<number>;
    pickUps?: Array<boolean>;
    updateTime?: number;
}

export interface ReportedScore extends Entity {
    strokes: Array<number>;
    watched: Array<number>;
    pickUps?: Array<boolean>;
    watchedPickUps?: Array<boolean>;
}

export interface Distance extends Entity {
    lengths: Array<DistanceValue>;
    winners?: Array<string>;
}

export interface ScoringData {
    format: ScoringFormat;
    mode: ScoringMode;
    handicaps?: Array<number>;
    holes?: Array<number>;
    awards?: boolean;
    mstablefordPoints?: Array<number>;
    stablefordMode?: boolean;
}

export interface Competition extends Entity {
    name?: string;
    order: number;
    eventOrRoundId?: string;
    roundOrder?: number;
    everyone: boolean;
    scoring: ScoringData;
    contactIds?: Array<string>;
    competitionGender?: EventGender;
    flights?: number;
    flightsNaming?: FlightsNamingMode;
    payoutsGross: Array<PayoutSettings | null>;
    payoutsNet: Array<PayoutSettings | null>;
    fromBothModes?: boolean;
    winners?: Array<WinnerInfo>;
}

export interface CompetitionLegacy extends Competition {
    tees?: Array<Tee | null>;
}

export interface PayoutSettings {
    enabled: boolean;
    payoutSchedule?: Array<number>;
    entryFee?: number;
    fixedAmount?: number;
    purseType?: PurseType;
    rounding?: boolean;
    roundingValue?: number;
    payoutMethod?: PayoutMethod;
    payoutPerHole?: number;
    valuePerSkin?: number;
}

export interface WinnerInfo {
    mode: 'gross' | 'net';
    flight: number;
    contactId: string;
}

export interface Tee {
    id: string;
    name: string;
    facilityId?: string;
    facilityName?: string;
    rating: number;
    slope: number;
    ratingFront: number;
    slopeFront: number;
    ratingBack: number;
    slopeBack: number;
    conguSss: number;
    handicapSystem: HandicapSystem;
    par: Array<Par>;
    handicap: Array<number>;
    handicap2?: Array<number>;
    len?: Array<number>;
    gender?: Gender;
    edited?: boolean;
    deleted?: boolean;
}

export interface CourseTee {
    isDeleted: Utils.Func<boolean>;
    getId: Utils.Func<string>;
    getTee: Utils.Func<Tee>;
    getUserTee: Utils.Func<Tee | undefined>;
    getMainTee: Utils.Func<Tee | undefined>;
}

export type Tees = Array<Tee>;
export type CourseTees = Array<CourseTee>;

export interface GolferGroup extends Entity {
    contactIds: Array<string>;
    order: number;
}

export interface Team extends Entity {
    contactIds: Array<string>;
    order: number;
    name?: string;
    gender?: Gender;
    handicapIndex?: number;
    scheduled?: boolean;
    withdrawn?: boolean;
    disqualified?: boolean;
    reportedBy?: string;
}

export interface TeeTimeSettings {
    startTime: number;
    startTimeUTC: number;
    mode: TeeTimeMode;
    interval: number; // for regular mode
    golfersPerGroup: number;
    startingHolesType?: StartingHolesType;
    startingHoles?: StartingHoles;
}

export interface Portal extends Entity {
    badgeUrl?: string;
    bannerUrl?: string;
    about?: string;
}

export interface SimpleCourse {
    id: string;
    name: string;
}

export interface CompoundCourse {
    facilityId: string;
    facilityName: string;
    outCourse?: SimpleCourse;
    inCourse?: SimpleCourse;
    courses: Array<SimpleCourse>;
}

export interface Facility {
    id: string;
    name: string;
    city?: CourseAddress;
    courses: Array<SimpleCourse>;
}

export interface CourseAddress {
    formattedString: string;
    pos: {
        lat: number,
        lon: number,
    };
}

export interface CourseResponse {
    city: CourseAddress;
    facilities: Array<Facility>;
}

export interface FacilitiesGroup {
    name?: string;
    loadResult?: string;
    loadStatus?: ResultStatus;
    facilities?: Array<Facility>;
}

export interface Contact extends Entity {
    firstName?: string;
    lastName: string;
    avatar?: string;
    handicapIndex?: number;
    handicapId?: string;
    playingHandicap?: number;
    gender?: Gender;
    hidden: boolean;
    scheduled?: boolean;
    tee?: Tee;
    reportedBy?: string;
    withdrawn?: boolean;
    disqualified?: boolean;
    installId?: string;
    homeCourseOrCity?: string;
    roundToken?: string;
    paymentId?: string;
    feePaid?: boolean;
    feePaidDate?: number;
    email?: string;
}

export interface TeeDetails {
    teeMen?: Tee;
    teeWomen?: Tee;
}

export interface ContactDetails extends Entity {
    // public
    firstName?: string;
    lastName: string;
    avatar?: string;
    handicapIndex?: number;
    handicapId?: string;
    gender?: Gender;
    hidden: boolean;
    tee?: Tee;
    roundTees?: { [s: string]: Tee; };
    reportedBy?: string;
    withdrawn?: boolean;
    disqualified?: boolean;
    homeCourseOrCity?: string;
    email?: string;
    phone?: string;
    notes?: string;
    installId?: string;
    roundToken?: string;
    paymentId?: string;
    feePaid?: boolean;
    feePaidDate?: number;
}

export interface ContactInvite extends Entity {
    hidden: boolean;
    email: string;
    inviteDate: number;
    inviteStatus: ContactInviteStatus;
    statusDate: number;
    firstName?: string;
    lastName?: string;
    cid?: string;
}

export interface ContactPayoutState {
    player: Team | Contact;
    names: string[];
    awards: Map<string, number>;
    total: number;
    pos: number;
}

export interface EventLog extends Entity {
    eventId: string;
    datetime: number;
    source: string;
    action: string;
    details: string;
    specs: string;
}

export interface EventStats extends Entity {
    participantsWithScores: number;
    participants: number;
    participantsWithAppScores: number;
}

export type StatsEntry = {
    eventId: string,
    date: number,
    ll: boolean
};

export interface UserStats extends Entity {
    eventsDates: StatsEntry[];
}

export interface User {
    uid: string;
    email?: string;
    creationTime: number;
    lastSignInTime: number;
    exp: number;
    eventsDates: number[];
}

export interface InviteCodeInfo extends Entity {
    eventId: string;
    groupId: string;
}

export interface AcceptedInvite extends Entity {
    adminId?: string;
    contactId?: string;
    installId: string;
    date?: number;
}

export interface EventsBinding {
    adminId: string;
    contactId: string;
    timestamp?: number;
}

export interface GolferOrTeam {
    id: string;
    handicapIndex?: number;
    gender?: Gender;
    scheduled?: boolean;
}

export interface AddGolfersRequest {
    eventId: string;
    source: string;
    action: string;
    token: string;
    userTime: number;
    userTimezoneOffset: number;
    invite?: ContactInvite;
    contact?: ContactDetails;
    invites?: Array<ContactInvite>;
    contacts?: Array<ContactDetails>;
    notificationLess?: boolean; // for action === ACTION_GOLFER_ADDED
    updateUpcoming?: boolean;
}

export interface AddGolfersResult {
    added: number;
    updated: number;
    total: number;
    timeSpent: number;
    limitExceeded: boolean;
    notificationFailed?: boolean;
    adminEmail?: string;
    maxEventContacts: number;
    emailAlreadyRegistered?: boolean;
    nameAlreadyRegistered?: boolean;
    registeredContactId?: string;
    emailUpdateContactIds: Set<string>;
}

export interface CreateLiveLeaderboardRequest {
    source: string;
    token: string;
    leaderboard: Event;
}

export interface CreateLiveLeaderboardResponse {
    leaderboard: Event;
}

export type RegularTeeTime = number;
export type ShotgunTeeTime = string;
export type TeeTime = RegularTeeTime | ShotgunTeeTime;

export interface ErrorInfo extends Entity {
    e?: string; // error message
    p: string; // path
    u?: string; // user id
    v: string; // version
    utcTime: string; // none-database time creation representational field
}

export interface AdminInvitedGolferInfo {
    userName: string;
    email?: string;
    installId: string;
    firstRoundEventId: string | null;
    isPremiumUser: boolean;
}

export interface EventData {
    adminEmail?: string;
    units?: Units;
    portal: Portal;
    portalLoaded: boolean;
    groupsMap: Map<string, Array<GolferGroup>>;
    golfersMap: Map<string, Map<string, Contact>>;
    golfers: Map<string, Contact>;
    roster: Map<string, ContactDetails>;
    golfersAggregated: Map<string, ContactDetails>;
    invitedContacts: Map<string, ContactInvite>;
    teamsListMap: Map<string, Array<Team>>;
    teamsMap: Map<string, Map<string, Team>>;
    competitionsMap: Map<string, Array<Competition>>;
    acceptedInvites: Map<string, AcceptedInvite>;
    teeTimesMap: Map<string, Array<TeeTime>>;
    rounds: Array<Round>;
    selectedRound?: Round;
    loadedTeams: number;
    loadedGroups: number;
    loadedGolfers: number;
    loadedRoster: number;
    loadedRounds: number;
    loadedCompetitions: number;
    loadedInvited: number;
    genTeeTimes: (eventOrRound: EventBase) => void;
    setSelectedRound: (round?: Round) => void;
}

export interface Announcement extends Entity {
    text: string;
    timestamp: number;
}

export function eventChange(id: string, key: keyof Event, value: any) {
    const toSave = { id } as any;
    toSave[key] = value;
    return toSave as EventBase;
}

export function rollEvents(event: Event, rounds: Array<Round>): Array<EventBase> {
    return event.type === 'multiday' ? rounds : [event];
}

export function allEvents(event: Event, rounds: Array<Round>): Array<EventBase> {
    return event.type === 'multiday' ? [event, ...rounds] : [event];
}

export function isRound(event: EventBase): event is Round {
    return 'eventId' in event;
}

export function isEvent(event: EventBase): event is Event {
    return !isRound(event);
}

export function eventName(event: EventBase) {
    return isEvent(event) ? event.name : isRound(event) ? 'Round ' + event.roundOrder : 'event.id';
}

export function eventLogId(eventOrRound: EventBase) {
    return eventOrRound.publicId;
}

export function masterEventId(eventOrRound: EventBase) {
    return isRound(eventOrRound) ? eventOrRound.eventId : eventOrRound.id;
}

export function isTodayEvent(event: Event, todayTime?: number) {
    const today = todayTime ?? Utils.getUserToday();
    const eventDate = event.date;
    const eventFinishDate = (event.finishDate ?? event.date);
    return today <= eventFinishDate && eventDate < today + Utils.DAY_MILLIS;
}

export function isPastEvent(event: Event, todayTime?: number) {
    const today = todayTime ?? Utils.getUserToday();
    const eventFinishDate = (event.finishDate ?? event.date);
    return eventFinishDate < today;
}

export function fixLegacyRounds(rounds: Array<Round>) {
    rounds.forEach(round => {
        const roundId = (round as any).roundId as number;
        if (!round.roundOrder && roundId) {
            round.roundOrder = roundId;
            delete (round as any).roundId;
        }
    });
    return rounds;
}

export interface Pro extends Entity {
    exp: number;
}

export function isRegular(teeTime: TeeTime): teeTime is RegularTeeTime {
    return typeof teeTime === 'number';
}

export function teeTimeName(time?: TeeTime): string {
    if (!time) {
        return 'n/a';
    }
    if (isRegular(time)) {
        return Utils.formatTime(time);
    }
    return time;
}

export const A1_18: Readonly<Array<number>> = Utils.range(0, 18);
export const A1_9: Readonly<Array<number>> = Utils.range(0, 9);
export const A10_18: Readonly<Array<number>> = Utils.range(9, 18);

export function getStartingHoles(startingHolesType: StartingHolesType, startingHoles: StartingHoles, holesRange: HolesRange, mode?: TeeTimeMode): Readonly<Array<number>> {
    startingHolesType = getStartingHolesType(startingHolesType, holesRange, mode);
    if (mode === 'shotgun') {
        if (startingHolesType === 'holes1_18') {
            return A1_18;
        } else if (startingHolesType === 'holes1_9') {
            return A1_9;
        } else if (startingHolesType === 'holes10_18') {
            return A10_18;
        } else if (startingHolesType === 'other' && startingHoles && startingHoles.length > 0) {
            return startingHoles;
        }
        return holesRange.first === 0 && holesRange.last === 18 ? A1_18 :
            holesRange.first === 0 && holesRange.last === 9 ? A1_9 : A10_18;

    } else {
        // 'regular'
        if (startingHolesType === 'hole1') {
            return [0];
        } else if (startingHolesType === 'hole10') {
            return [9];
        } else if (startingHolesType === 'other' && startingHoles && startingHoles.length > 0) {
            return [startingHoles[0]];
        }
        return holesRange.first === 0 ? [0] : [9];
    }
}

export function getStartingHolesType(startingHolesType: StartingHolesType, holesRange: HolesRange, mode?: TeeTimeMode): StartingHolesType {
    if (mode === 'shotgun') {
        if (startingHolesType) {
            return startingHolesType;
        }
        return holesRange.first === 0 && holesRange.last === 18 ? 'holes1_18' :
            holesRange.first === 0 && holesRange.last === 9 ? 'holes1_9' : 'holes10_18';
    } else {
        // 'regular'
        if (startingHolesType) {
            return startingHolesType;
        }
        return holesRange.first === 0 ? 'hole1' : 'hole10';
    }
}

export function isCompoundCourse(course: Course): course is CompoundCourse {
    return (course as CompoundCourse).facilityId !== undefined;
}

export function isCompoundFacility(facility?: Facility): boolean {
    return (facility?.courses?.length ?? 0) > 1;
}

export function getCourseName(course?: Course) {
    if (!course) {
        return '';
    }
    if (isCompoundCourse(course)) {
        if (course.outCourse?.id && course.inCourse?.id) {
            return course.facilityName + ' (' + course.outCourse.name + ', ' + course.inCourse.name + ')';
        } else if (course.outCourse?.id) {
            return course.facilityName + ' (' + course.outCourse.name + ')';
        } else if (course.inCourse?.id) {
            return course.facilityName + ' (' + course.inCourse.name + ')';
        } else {
            return course.facilityName;
        }
    }
    return course.name;
}

export function getCurrentRound(event: Event, rounds?: Array<Round>) {
    if (event.type === 'multiday' && rounds?.length) {
        const today = Utils.getUserToday();
        const todayRound = rounds.find(r => today <= r.date && r.date < today + Utils.DAY_MILLIS) ?? rounds[0];
        return todayRound;
    } else {
        return undefined;
    }
}

export function getCurrentCourseName(event: Event, rounds?: Array<Round>) {
    if (event.type === 'multiday' && rounds?.length) {
        return getCourseName(getCurrentRound(event, rounds)?.course);
    } else {
        return getCourseName(event.course);
    }
}

export function getCourseNameExt(event: Event, rounds?: Array<Round>) {
    const multiday = event.type === 'multiday';
    const courseSet = new Set<string>();
    rounds?.forEach(round => {
        if (round.course) {
            courseSet.add(getCourseName(round.course));
        }
    });
    let courseName = getCourseName(multiday ? (rounds?.length ? rounds[0].course : undefined) : event.course) ?? 'Course not selected';
    if (multiday && rounds?.length && rounds[0].course && courseSet.size > 1) {
        courseName += ` and ${courseSet.size - 1} more`;
    }
    return courseName;
}

export function facilityFromCourse(course?: Course): Facility | undefined {
    if (!course) {
        return undefined;
    }
    if (isCompoundCourse(course)) {
        return {
            id: course.facilityId,
            name: course.facilityName,
            courses: course.courses
        };
    } else {
        return {
            id: course.id,
            name: course.name,
            courses: [course]
        };
    }
}

export function compareEvents(a: Event, b: Event) {
    if (b.date === a.date) {
        return a.name.localeCompare(b.name);
    }
    return b.date - a.date;
}

export function getHolesRange(holesType?: HolesType): HolesRange {
    switch (holesType) {
        case HOLES_9: return { first: 0, last: 9 };
        case HOLES_FRONT_9: return { first: 0, last: 9 };
        case HOLES_BACK_9: return { first: 9, last: 18 };
        default: return { first: 0, last: 18 };
    }
}

export function getTotalHoles(holesType?: HolesType): number {
    const holesRange = getHolesRange(holesType);
    return holesRange.last - holesRange.first;
}

const HOLE_LABELS_SUBTRUCT = false;

export function getHoleLabel(hole: number, holesRange: HolesRange): number {
    if (HOLE_LABELS_SUBTRUCT) {
        return hole + 1 - holesRange.first;
    } else {
        return hole + 1;
    }
}

export function isNetMode(mode: ScoringMode) {
    return mode === 'net' || mode === 'gross+net';
}

export function isGrossMode(mode: ScoringMode) {
    return mode === 'gross' || mode === 'gross+net';
}

export function isStablefordScoring(scoring: ScoringData) {
    return scoring.format === ScoringFormatIndividual.stableford || scoring.format === ScoringFormatIndividual.modified_stableford;
}

export function isStablefordScoringOrMode(scoring: ScoringData) {
    return isStablefordScoring(scoring) || Boolean(isTeamScoring(scoring) && scoring.stablefordMode);
}

export function isLongestDriveScoring(scoring: ScoringData) {
    return scoring.format === ScoringFormatDistance.longest_drive;
}

export function isKPScoring(scoring: ScoringData) {
    return scoring.format === ScoringFormatDistance.closest_to_the_pin;
}

export function isDistanceScoring(scoring: ScoringData) {
    return scoring.format === ScoringFormatDistance.longest_drive ||
        scoring.format === ScoringFormatDistance.closest_to_the_pin;
}

export function isSkinsScoring(scoring: ScoringData) {
    return scoring.format === ScoringFormatSkins.skins_individual ||
        scoring.format === ScoringFormatSkins.skins_team;
}

export function isSideGameScoring(scoring: ScoringData) {
    return isDistanceScoring(scoring);
}

export function isTeamScoring(scoring: ScoringData) {
    return Object.keys(ScoringFormatTeams).indexOf(scoring.format) >= 0;
}

export function isTeamFormat(scoring: ScoringData) {
    return isTeamScoring(scoring) || scoring.format === ScoringFormatSkins.skins_team;
}

export function isTeamFormatExceptBB(scoring: ScoringData, mainCompetition?: Competition) {
    return isTeamScoringExceptBB(scoring) || (scoring.format === ScoringFormatSkins.skins_team
        && mainCompetition?.scoring?.format !== ScoringFormatTeams.best_ball);
}

export function isTeamScoringExceptBB(scoring: ScoringData) {
    return isTeamScoring(scoring) && scoring.format !== ScoringFormatTeams.best_ball;
}

export function isIndividualScoring(scoring: ScoringData) {
    return Object.keys(ScoringFormatIndividual).indexOf(scoring.format) >= 0;
}

export function isIndividualScoringOrBB(scoring: ScoringData) {
    return Object.keys(ScoringFormatIndividual).indexOf(scoring.format) >= 0 || scoring.format === ScoringFormatTeams.best_ball;
}

export function isCompatibleScores(scoringA: ScoringData, scoringB: ScoringData, mainCompetition?: Competition) {
    if (isDistanceScoring(scoringA)) {
        return scoringA.format === scoringB.format;
    }
    return isTeamFormatExceptBB(scoringA, mainCompetition) === isTeamFormatExceptBB(scoringB, mainCompetition);
}

export function isFirstOverSecond(firstScoring: ScoringData, secondScoring: ScoringData, mainCompetition?: Competition) {
    if (isMainScoring(firstScoring)) {
        if (isCompatibleScores(firstScoring, secondScoring, mainCompetition)) {
            return (!isNetMode(secondScoring.mode) && isNetMode(firstScoring.mode)) ||
                (isNetMode(secondScoring.mode) === isNetMode(firstScoring.mode) && secondScoring.format !== 'strokeplay' && firstScoring.format === 'strokeplay');
        }
    }
    return false;
}

export function isMainScoring(scoring: ScoringData) {
    return isTeamScoring(scoring) || isIndividualScoring(scoring) || isSkinsScoring(scoring);
}

export function isGolferCompetitionParticipant(golfer: Contact, competition: Competition, teams?: Map<string, Team>) {
    if (!isTeamScoring(competition.scoring)) {
        if (competition.everyone) {
            return !competition.competitionGender || (competition.competitionGender === 'both')
                || (competition.competitionGender === 'men' && golfer.gender === 'male')
                || (competition.competitionGender === 'women' && golfer.gender === 'female');
        } else {
            return competition.contactIds && competition.contactIds!.indexOf(golfer.id) > -1;
        }
    } else {
        return teams && (competition.everyone || (competition.contactIds && Array.from<Team>(teams.values())
            .some(team => competition.contactIds!.indexOf(team.id) > -1 && team.contactIds.indexOf(golfer.id) > -1)));
    }
}

export function isGrossPayouts(competition: Competition) {
    return (isGrossMode(competition.scoring.mode) && competition.payoutsGross && competition.payoutsGross.length > 0 && competition.payoutsGross[0]?.enabled) || false;
}

export function isNetPayouts(competition: Competition) {
    return (isNetMode(competition.scoring.mode) && competition.payoutsNet && competition.payoutsNet.length > 0 && competition.payoutsNet[0]?.enabled) || false;
}

export function skinsWithCarryOvers(competition: Competition) {
    if (!competition?.scoring || !isSkinsScoring(competition.scoring)) {
        return false;
    }
    return (isNetMode(competition.scoring.mode) && competition?.payoutsNet[0]?.payoutMethod === 'carryovers') ||
        (isGrossMode(competition.scoring.mode) && competition?.payoutsGross[0]?.payoutMethod === 'carryovers');
}

export function isSideScoringWithPayouts(competition: Competition) {
    return ((isKPScoring(competition.scoring) || isLongestDriveScoring(competition.scoring)) && competition.payoutsGross && competition.payoutsGross.length > 0 && competition.payoutsGross[0]?.enabled) || false;
}

export function getDefaultPayoutSettings(competition: Competition) {
    const isSkins = isSkinsScoring(competition.scoring);
    return { enabled: false, purseType: isSkins ? 'valuePerSkin' : 'fixed', payoutMethod: 'divided' } as PayoutSettings;
}

export function getPayoutSettings(competition: Competition) {
    const isNet = isNetMode(competition.scoring.mode);
    const isGross = isGrossMode(competition.scoring.mode);
    const payoutGrossSettings: PayoutSettings = isNet && isGross && competition.payoutsGross[0] ?
        competition.payoutsGross[0] :
        getDefaultPayoutSettings(competition);
    const payoutNetSettings: PayoutSettings = isNet && isGross && competition.payoutsNet[0] ?
        competition.payoutsNet[0] :
        getDefaultPayoutSettings(competition);
    const payoutSettings: PayoutSettings = (!isNet || !isGross) && (isNet && competition.payoutsNet[0]) ? competition.payoutsNet[0] :
        (isGross && competition.payoutsGross[0]) ? competition.payoutsGross[0] : getDefaultPayoutSettings(competition);
    return { payoutGrossSettings, payoutNetSettings, payoutSettings };
}

export function getGolferMainCompetition(golfer: Contact, competitions: Array<Competition>, teams?: Map<string, Team>) {
    const eventMainCompetition = getEventMainCompetition(competitions);
    let competition;
    for (const comp of competitions) {
        if (isMainScoring(comp.scoring) && isGolferCompetitionParticipant(golfer, comp, teams)) {
            if (!competition || isFirstOverSecond(comp.scoring, competition.scoring, eventMainCompetition)) {
                competition = comp;
            }
        }
    }
    return competition;
}

export function golfersOrTeams(contactsOrTeams: Map<string, Contact> | Map<string, Team>, groups: Array<GolferGroup>, includeAll: boolean) {
    const golfersOrTeamsMap = new Map<string, GolferOrTeam>();
    const scheduledGolfersOrTeams = new Set<string>();
    groups.forEach(group => group.contactIds.forEach(contactId => scheduledGolfersOrTeams.add(contactId)));
    contactsOrTeams.forEach((cot: Contact | Team) => {
        if (includeAll || !scheduledGolfersOrTeams.has(cot.id)) {
            golfersOrTeamsMap.set(cot.id, {
                id: cot.id,
                handicapIndex: cot.handicapIndex,
                gender: cot.gender,
                scheduled: scheduledGolfersOrTeams.has(cot.id)
            });
        }
    });
    return golfersOrTeamsMap;
}

export function getTee(eventOrRound: EventBase, competition?: Competition | null, gender?: Gender, contact?: Contact) {
    if (competition && isSideGameScoring(competition.scoring)) {
        return undefined;
    }
    if (contact && contact.tee) {
        return contact.tee;
    }
    if (gender === 'female') {
        return eventOrRound.teeWomen;
    } else {
        return eventOrRound.teeMen;
    }
}

// TODO: legacy
function getLegacyFlightTee(competition: CompetitionLegacy, flight: number, gender?: Gender) {
    if (!competition.tees) {
        return undefined;
    }
    return !gender || gender === 'male' ?
        (competition.tees[flight * 2] ?? undefined) :
        (competition.tees[flight * 2 + 1] ?? undefined);
}

// TODO: legacy
function getLegacyTee(eventOrRound: EventBase, competition?: CompetitionLegacy, gender?: Gender, flight?: number) {
    if (!competition) {
        return undefined;
    }
    if (competition && isSideGameScoring(competition.scoring)) {
        return undefined;
    }
    if (gender === 'female' && eventOrRound.teeWomen) {
        return eventOrRound.teeWomen;
    }
    if (gender === 'male' && eventOrRound.teeMen) {
        return eventOrRound.teeMen;
    }
    if (competition && competition.tees) {
        const flightTee = getLegacyFlightTee(competition, flight || 0, gender);
        if (flightTee) {
            return flightTee;
        } else {
            return getLegacyFlightTee(competition, 0, gender);
        }
    }
    return undefined;
}

export function fixLegacyTees(event: Event, competitions: Array<Competition>) {
    if (!event.teeMen || !event.teeWomen) {
        const mainComp = event.appCompetition ?? getEventMainCompetition(competitions);
        if (!event.teeMen) {
            event.teeMen = getLegacyTee(event, mainComp, 'male');
        }
        if (!event.teeWomen) {
            event.teeWomen = getLegacyTee(event, mainComp, 'female');
        }
        Utils.dbgLog(`Using legacy tees: men=${event.teeMen?.id}, women=${event.teeWomen?.id}`);
        return true;
    } else {
        return false;
    }
}

export function golfersOfCompetition(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>): Array<Contact> {
    const contacts: Array<Contact> = [];
    if ((competition.scoring.format === ScoringFormatTeams.best_ball ||
        competition.scoring.format === ScoringFormatSkins.skins_team) && competition.everyone) {
        teams.forEach(team => {
            if (team?.contactIds?.length > 0) {
                team.contactIds.forEach(contactId => {
                    const contact = golfers.get(contactId);
                    if (contact && !contact.hidden) {
                        contacts.push(contact);
                    }
                });
            }
        });
        return contacts;
    }
    if (competition.everyone || isDistanceScoring(competition.scoring)) {
        let res: Array<Contact> = [];
        if (competition.competitionGender === 'men') {
            golfers.forEach(golfer => {
                if (golfer.gender === 'male' && !golfer.hidden) {
                    res.push(golfer);
                }
            });
        } else if (competition.competitionGender === 'women') {
            golfers.forEach(golfer => {
                if (golfer.gender === 'female' && !golfer.hidden) {
                    res.push(golfer);
                }
            });
        } else {
            res = Array.from(golfers.values()).filter(g => !g.hidden);
        }
        return res;
    }
    if (competition.contactIds) {
        if (isTeamFormat(competition.scoring)) {
            competition.contactIds.forEach(teamId => {
                if (teams.has(teamId)) {
                    teams.get(teamId)!.contactIds.forEach(contactId => {
                        if (golfers.has(contactId) && !golfers.get(contactId)!.hidden) {
                            contacts.push(golfers.get(contactId)!);
                        }
                    });
                }
            });
        } else {
            competition.contactIds.forEach(contactId => {
                if (golfers.has(contactId) && !golfers.get(contactId)!.hidden) {
                    contacts.push(golfers.get(contactId)!);
                }
            });
        }
    }
    return contacts;
}

export function isSameScoring(scoring1: ScoringData, scoring2: ScoringData) {
    return scoring1.format === scoring2.format &&
        scoring1.mode === scoring2.mode &&
        Boolean(scoring1.stablefordMode) === Boolean(scoring2.stablefordMode);
}

export function isTotalingCompetition(competition: Competition) {
    return !isDistanceScoring(competition.scoring) &&
        !isSkinsScoring(competition.scoring);
}

export function isFirstRoundCompetition(competition: Competition) {
    return competition.roundOrder === 1 &&
        isTotalingCompetition(competition);
}

export function getRoundsCompetitions(competition: Competition, competitions: Array<Competition>) {
    if (isFirstRoundCompetition(competition)) {
        const comps = competitions.filter(c => c.roundOrder && c.roundOrder > 1 && c.id === competition.id);
        return [competition].concat(comps);
    } else {
        return [competition];
    }
}

export function hasFirstRoundCompetition(competition: Competition, competitions: Array<Competition>) {
    if (competition.roundOrder && competition.roundOrder > 1 &&
        !isDistanceScoring(competition.scoring) &&
        !isSkinsScoring(competition.scoring)) {
        return competitions.some(c => c.roundOrder === 1 && c.id === competition.id);
    } else {
        return false;
    }
}

export function teamGender(team: Team, golfers: Map<string, Contact>): EventGender | undefined {
    if (team.contactIds) {
        let men = 0;
        let women = 0;
        team.contactIds.forEach(contactId => {
            const contact = golfers.get(contactId);
            if (contact && !contact.hidden) {
                if (contact.gender === 'male') {
                    men++;
                }
                if (contact.gender === 'female') {
                    women++;
                }
            }
        });
        if (men > 0 && women > 0) {
            return 'both';
        }
        if (men > 0) {
            return 'men';
        }
        if (women > 0) {
            return 'women';
        }
    }
    return undefined;
}

export function teamsOf(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>): Array<Team> {
    let res: Array<Team> = [];
    if (competition.everyone) {
        if (competition.competitionGender === 'men') {
            teams.forEach(team => {
                if (teamGender(team, golfers) === 'men') {
                    res.push(team);
                }
            });
        } else if (competition.competitionGender === 'women') {
            teams.forEach(team => {
                if (teamGender(team, golfers) === 'women') {
                    res.push(team);
                }
            });
        } else {
            res = Array.from(teams.values());
        }
    } else if (competition.contactIds) {
        if (isTeamFormat(competition.scoring)) {
            competition.contactIds.forEach(teamId => {
                const team = teams.get(teamId);
                if (team) {
                    res.push(team);
                }
            });
        }
    }
    return res;
}

export function getTeamOfContact(teams: Map<string, Team>, contact: Contact) {
    const teamArray = Array.from(teams.values());
    const teamIndex = teamArray.findIndex(t => t.contactIds.indexOf(contact.id) > -1);
    return teamIndex > -1 ? teamArray[teamIndex] : undefined;
}

function everyoneTeamCount(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>) {
    let count = 0;
    if (competition.competitionGender === 'men') {
        teams.forEach(team => count += teamGender(team, golfers) === 'men' ? 1 : 0);
    } else if (competition.competitionGender === 'women') {
        teams.forEach(team => count += teamGender(team, golfers) === 'women' ? 1 : 0);
    } else {
        count = Array.from(teams.values())
            .filter(t => t.contactIds.some(golferId => golfers.has(golferId) && !golfers.get(golferId)!.hidden))
            .length;
    }
    return count;
}

function everyoneCount(competition: Competition, golfers: Map<string, Contact>) {
    let count = 0;
    if (competition.competitionGender === 'men') {
        golfers.forEach(golfer => count += golfer.gender === 'male' && !golfer.hidden ? 1 : 0);
    } else if (competition.competitionGender === 'women') {
        golfers.forEach(golfer => count += golfer.gender === 'female' && !golfer.hidden ? 1 : 0);
    } else {
        count = Array.from(golfers.values()).filter(g => !g.hidden).length;
    }
    return count;
}

function golfersOfCompetitionCount(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, flight: number) {
    let contacts = 0;
    if (competition.everyone) {
        contacts = everyoneCount(competition, golfers);
    }
    if (competition.contactIds) {
        if (isTeamFormat(competition.scoring)) {
            competition.contactIds.forEach(teamId => {
                if (teams.has(teamId)) {
                    teams.get(teamId)!.contactIds.forEach(contactId => {
                        if (golfers.has(contactId) && !golfers.get(contactId)!.hidden) {
                            contacts++;
                        }
                    });
                }
            });
        } else {
            competition.contactIds.forEach(contactId => {
                if (golfers.has(contactId) && !golfers.get(contactId)!.hidden) {
                    contacts++;
                }
            });
        }
    }
    if (flight && competition.flights) {
        contacts = Math.floor(contacts / competition.flights) + (flight <= contacts % competition.flights ? 1 : 0);
    }
    return contacts;
}

function teamsOfCompetitionCount(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, flight: number) {
    let count = 0;
    if (competition.everyone) {
        return everyoneTeamCount(competition, golfers, teams);
    }
    if (competition.contactIds) {
        competition.contactIds.forEach(teamId => {
            if (teams.has(teamId)) {
                count++;
            }
        });
    }
    if (flight && competition.flights) {
        count = Math.floor(count / competition.flights) + (flight <= count % competition.flights ? 1 : 0);
    }
    return count;
}

export function getCompetitionFlightScoresCount(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, flight: number) {
    if (isTeamFormat(competition.scoring)) {
        return teamsOfCompetitionCount(competition, golfers, teams, flight);
    } else {
        return golfersOfCompetitionCount(competition, golfers, teams, flight);
    }
}

export function getParticipantsCount(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>) {
    if (competition.everyone) {
        if (isTeamFormat(competition.scoring)) {
            return everyoneTeamCount(competition, golfers, teams);
        } else {
            return everyoneCount(competition, golfers);
        }
    } else {
        let res = 0;
        if (competition.contactIds) {
            if (isTeamFormat(competition.scoring)) {
                competition.contactIds.forEach(cid => {
                    if (teams.has(cid)) {
                        res++;
                    }
                });
            } else {
                competition.contactIds.forEach(cid => {
                    if (golfers.has(cid) && !golfers.get(cid)!.hidden) {
                        res++;
                    }
                });
            }
        }
        return res;
    }
}

export function getFlightParticipantsCount(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, flightNumber: number) {
    const flightsCount = (competition.flights || 2);
    const competitionParticipantsCount = getParticipantsCount(competition, golfers, teams);
    return Math.floor(competitionParticipantsCount / flightsCount) + (flightNumber <= competitionParticipantsCount % flightsCount ? 1 : 0);
}

function compareByHCP(a: Contact | Team, b: Contact | Team) {
    return (a.handicapIndex || 0) - (b.handicapIndex || 0);
}

export function contactsOfFlight(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, flightNumber: number) {
    const flightsParticipantsCounts = Utils.range(0, flightNumber).map(i => getFlightParticipantsCount(competition, golfers, teams, i + 1)).reduce((acc, cur) => acc += cur);
    const flightParticipantsCount = getFlightParticipantsCount(competition, golfers, teams, flightNumber);
    if (isTeamFormat(competition.scoring)) {
        return new Array<Contact>();
    } else {
        return golfersOfCompetition(competition, golfers, teams).sort(compareByHCP).slice(flightsParticipantsCounts > flightParticipantsCount ? flightsParticipantsCounts - flightParticipantsCount : 0, flightsParticipantsCounts);
    }
}

export function teamsOfFlight(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, flightNumber: number) {
    const flightsParticipantsCounts = Utils.range(0, flightNumber).map(i => getFlightParticipantsCount(competition, golfers, teams, i + 1)).reduce((acc, cur) => acc += cur);
    const flightParticipantsCount = getFlightParticipantsCount(competition, golfers, teams, flightNumber);
    if (isTeamFormat(competition.scoring)) {
        return teamsOf(competition, golfers, teams).sort(compareByHCP).slice(flightsParticipantsCounts > flightParticipantsCount ? flightsParticipantsCounts - flightParticipantsCount : 0, flightsParticipantsCounts);
    } else {
        return new Array<Team>();
    }
}

export function getFlightOfTeam(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, team: Team) {
    if (!isTeamFormat(competition.scoring)) {
        return 0;
    }
    if (!competition.flights) {
        return 0;
    }
    const teamIndex = teamsOf(competition, golfers, teams).sort(compareByHCP).findIndex(t => t.id === team.id);
    return findFlightByParticipantIndex(competition, teamIndex, getParticipantsCount(competition, golfers, teams));
}

export function getFlightOfContact(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, contact?: Contact) {
    if (!competition.flights) {
        return undefined;
    }
    if (!contact) {
        return undefined;
    }
    if (isTeamFormat(competition.scoring)) {
        const team = getTeamOfContact(teams, contact);
        return team ? getFlightOfTeam(competition, golfers, teams, team) : undefined;
    }
    const contactIndex = golfersOfCompetition(competition, golfers, teams).sort(compareByHCP).findIndex(c => c.id === contact.id);
    return findFlightByParticipantIndex(competition, contactIndex, getParticipantsCount(competition, golfers, teams));
}

export function getFlightNumbersForIds(competition: Competition, golfers: Map<string, Contact>, teams: Map<string, Team>, scoredParticipantIds: Set<string>) {
    if (!competition.flights || !scoredParticipantIds?.size || !isMainScoring(competition.scoring)) {
        return undefined;
    }
    const flightIndexes = new Set<number>();
    const participantsCount = getParticipantsCount(competition, golfers, teams);
    if (isTeamFormat(competition.scoring)) {
        const sortedTeams = teamsOf(competition, golfers, teams).sort(compareByHCP);
        for (let i = 0; i < sortedTeams.length; ++i) {
            const team = sortedTeams[i];
            if (scoredParticipantIds.has(team.id)) {
                const flightIndex = findFlightByParticipantIndex(competition, i, participantsCount);
                if (flightIndex) {
                    flightIndexes.add(flightIndex);
                }
            }
        }
    } else {
        const sortedGolfers = golfersOfCompetition(competition, golfers, teams).sort(compareByHCP);
        for (let i = 0; i < sortedGolfers.length; ++i) {
            const golfer = sortedGolfers[i];
            if (scoredParticipantIds.has(golfer.id)) {
                const flightIndex = findFlightByParticipantIndex(competition, i, participantsCount);
                if (flightIndex) {
                    flightIndexes.add(flightIndex);
                }
            }
        }
    }
    return flightIndexes;
}

function findFlightByParticipantIndex(competition: Competition, participantIndex: number, compParticipantsCount: number) {
    if (participantIndex < 0 || !competition.flights) {
        return undefined;
    } else {
        let i = 1;
        let acc = 0;
        while (i <= competition.flights && acc < participantIndex) {
            acc += Math.floor(compParticipantsCount / competition.flights) + (i <= compParticipantsCount % competition.flights ? 1 : 0);
            if (acc <= participantIndex) {
                ++i;
            }
        }
        return i;
    }
}

export function getGroupFullness(event: EventBase, group: GolferGroup): number {
    return group.contactIds.length - golfersOrTeamsPerGroup(event);
}

export function golfersOrTeamsPerGroup(event: EventBase, golfersPerGroup?: number) {
    const perGroup = golfersPerGroup ?? event.teeTime.golfersPerGroup;
    return perGroup < event.teamSize ? 1 : Math.floor(perGroup / event.teamSize);
}

export function groupsPerTime(event: EventBase, golfersOrTeamsCount: number) {
    return Math.ceil(golfersOrTeamsCount / golfersOrTeamsPerGroup(event));
}

export function teamsCount(event: EventBase, golfersOrTeamsCount: number) {
    return Math.ceil(golfersOrTeamsCount / event.teamSize);
}

export function getEmptyGroups(existingGroups: Array<GolferGroup>): GolferGroup[] {
    const groups: GolferGroup[] = [];
    existingGroups.forEach(g => groups.push({ id: g.id, order: g.order, contactIds: [] }));
    return groups;
}

export function basicGroupsCount(groups: Array<GolferGroup>, groupSize: number, listToSchedule: Array<GolferOrTeam>) {
    const availableRooms = groups.map(g => groupSize - g.contactIds.length).reduce((acc, cur) => acc += cur, 0);
    return groups.length + Math.ceil((listToSchedule.length - availableRooms) / groupSize);
}

export function groupsCount(groups: Array<GolferGroup>, groupSize: number, teeTimesLength: number, pairing: boolean, contactsOrTeams: Map<string, GolferOrTeam>, genderMode: GenderMode) {
    const listToSchedule = Array.from(contactsOrTeams.values()).filter(gt => !gt.scheduled);
    if (genderMode === 'random') {
        return basicGroupsCount(groups, groupSize, listToSchedule);
    }
    const men = listToSchedule.filter(gt => gt.gender === 'male');
    const women = listToSchedule.filter(gt => gt.gender === 'female');
    if (genderMode === 'gender') {
        const mensGroupCount = Math.ceil(men.length / groupSize);
        const womensGroupCount = Math.ceil(women.length / groupSize);
        return mensGroupCount + womensGroupCount;
    } else { // MIXED
        return pairing ? Math.min(Math.ceil(listToSchedule.length / groupSize), Math.min(men.length, women.length)) : Math.min(Math.ceil(teeTimesLength), Math.min(men.length, women.length));
    }
}

// RS: using separate function due to warning: Function declared in a loop contains unsafe references to variable(s) 'nextOrder' no-loop-func
function findIndex(groups: Array<GolferGroup>, nextOrder: number) {
    return groups.findIndex(g => g.order === nextOrder);
}

function initGroups(groupCount: number, existingGroups: Array<GolferGroup>, useExisting?: boolean): GolferGroup[] {
    const groups: GolferGroup[] = [];
    let added = 0;
    let nextOrder = 0;
    while (added < groupCount) {
        const existingIdx = findIndex(existingGroups, nextOrder);
        if (existingIdx < 0) {
            groups.push({ id: '', order: nextOrder, contactIds: [] });
            added++;
        } else if (useExisting || existingGroups[existingIdx].contactIds.length === 0) {
            const g = existingGroups[existingIdx];
            groups.push({ id: g.id, order: g.order, contactIds: [...g.contactIds] });
            added++;
        }
        nextOrder++;
    }
    return groups;
}

function nextNotFull(groups: Array<GolferGroup>, teamSize: number, current?: number) {
    for (let i = 0; i < groups.length; ++i) {
        if ((groups[i].contactIds.length < teamSize) && (!current || i >= current)) {
            return groups[i];
        }
    }
    return null;
}

function nextNotScheduled(contactsOrTeams: Array<GolferOrTeam>, reverse?: boolean) {
    for (let i = 0; i < contactsOrTeams.length; ++i) {
        const j = !reverse ? i : contactsOrTeams.length - i - 1;
        if (!contactsOrTeams[j].scheduled) {
            return contactsOrTeams[j];
        }
    }
    return null;
}

function pair(groups: Array<GolferGroup>, contactsOrTeams: Array<GolferOrTeam>, teamSize: number, handicapMode: HandicapMode): Array<GolferGroup> {
    if (handicapMode === 'random') {
        Utils.shuffle(contactsOrTeams);
    } else {
        contactsOrTeams.sort((a, b) => (b?.handicapIndex || 0) - (a?.handicapIndex || 0));
    }
    let groupIndex = 0;
    let group = nextNotFull(groups, teamSize, groupIndex);
    let golfer = nextNotScheduled(contactsOrTeams, handicapMode === 'handicap' && !!group && ((group.contactIds.length % 2) !== 0));
    const mostGolfersPerTeam = Math.ceil(contactsOrTeams.length / groups.length);
    const mostGolfersTeamCount = contactsOrTeams.length % groups.length;
    while (group && golfer) {
        if (handicapMode === 'random') {
            golfer = nextNotScheduled(contactsOrTeams);
            if (golfer) {
                group.contactIds.push(golfer.id);
                golfer.scheduled = true;
            }
        } else if (handicapMode === 'handicap') {
            golfer = null;
            for (let i = 0; i < mostGolfersPerTeam; ++i) {
                if ((i === mostGolfersPerTeam - 1) && (mostGolfersTeamCount > 0 && group.order > mostGolfersTeamCount - 1)) {
                    break;
                }
                golfer = nextNotScheduled(contactsOrTeams);
                if (golfer && group.contactIds.length < teamSize) {
                    group.contactIds.push(golfer.id);
                    golfer.scheduled = true;
                }
            }
        } else {
            golfer = nextNotScheduled(contactsOrTeams, group.contactIds.length % 2 !== 0);
            if (golfer && !golfer.scheduled) {
                group.contactIds.push(golfer.id);
                golfer.scheduled = true;
            }
        }
        if (handicapMode !== 'random' || group.contactIds.length === teamSize) {
            group = nextNotFull(groups, teamSize, ++groupIndex);
        }
        if (!group) {
            if (!golfer) {
                break;
            } else {
                groupIndex = 0;
                group = nextNotFull(groups, teamSize, groupIndex);
            }
        }
    }
    return groups;
}

export function makeNewGroups(groups: Array<GolferGroup>, groupSize: number, teeTimesLength: number, pairing: boolean, contactsOrTeams: Array<GolferOrTeam>, handicapMode: HandicapMode, genderMode: GenderMode) {
    let newGroups = new Array<GolferGroup>();
    const listToSchedule = contactsOrTeams.filter(gt => !gt.scheduled);
    if (genderMode === 'random') {
        const allGroupCount = basicGroupsCount(groups, groupSize, listToSchedule);
        newGroups = pair(initGroups(allGroupCount, groups, true), listToSchedule, groupSize, handicapMode);
    } else {
        const men = listToSchedule.filter(gt => gt.gender === 'male');
        const women = listToSchedule.filter(gt => gt.gender === 'female');
        if (genderMode === 'gender') {
            const mensGroupCount = Math.ceil(men.length / groupSize);
            const mensGroups = pair(initGroups(mensGroupCount, groups), men, groupSize, handicapMode);
            const womensGroupCount = Math.ceil(women.length / groupSize);
            const womensGroups = pair(initGroups(womensGroupCount, mensGroups), women, groupSize, handicapMode);
            newGroups = newGroups.concat(mensGroups).concat(womensGroups);
        } else { // mixed
            const avgMensHcp = Utils.evalAvg(men.map(m => m.handicapIndex || 0));
            const avgWomensHcp = Utils.evalAvg(women.map(w => w.handicapIndex || 0));
            const allGroupCount = pairing ? Math.min(Math.ceil(listToSchedule.length / groupSize), Math.min(men.length, women.length)) : Math.min(Math.ceil(teeTimesLength), Math.min(men.length, women.length));
            newGroups = initGroups(allGroupCount, groups);
            newGroups = pair(newGroups, avgMensHcp >= avgWomensHcp ? men : women, groupSize - 1, handicapMode);
            newGroups = pair(newGroups, avgMensHcp >= avgWomensHcp ? women : men, groupSize, handicapMode);
        }
    }
    return newGroups;
}

export function generateGroups(event: EventBase, golfers: Map<string, Contact>, teams: Map<string, Team>, inputGroups: Array<GolferGroup>) {
    const groupsPerTimeNum = groupsPerTime(event, event.teamSize === 1 ? golfers.size : teams.size);
    const contactsOrTeams: Map<string, GolferOrTeam> = event.teamSize === 1 ? golfers : teams;
    const groups: Array<GolferGroup> = [];
    const maxOrder = inputGroups.reduce((acc, g) => Math.max(g.order, acc), 0) + 1;
    const maxCount = Math.max(groupsPerTimeNum, maxOrder);
    for (let i = 0; i < maxCount; i++) {
        let group = inputGroups.find(g => g.order === i);
        if (group) {
            for (let j = group.contactIds.length - 1; j >= 0; j--) {
                if (!contactsOrTeams.get(group.contactIds[j])) {
                    group.contactIds.splice(j, 1);
                }
            }
        } else {
            group = {
                id: '',
                contactIds: [],
                order: i
            };
        }
        groups.push(group);
    }
    if (groups.length === 0 || groups[groups.length - 1].contactIds.length > 0) {
        groups.push({ id: '', contactIds: [], order: groups.length });
    }
    return groups;
}

export interface ContactInfo {
    id: string;
    handicapIndex?: number;
    contacts: Array<Contact>;
    playingHandicaps: Array<number>;
    withdrawn?: boolean;
    disqualified?: boolean;
    teamPlayingHandicap?: number;
    handicapAllowance?: number;
}

export interface BaseScoringInfo {
    contactId: string;
    uniqueId: string;
    pos: string;
    totalHoles: number;
    holes: number; // thru
    total: number;
    relativeTotal: number;
    net: number;
    relativeNet: number;
    stableford: number;
    stablefordNet: number;
}

export interface BaseScoringState extends BaseScoringInfo {
    event: EventBase;
    tee: Tee;
    teeTime?: TeeTime;
    courseHandicap: number;
    reported?: Array<number | undefined>;
    score?: Array<number | undefined>;
    nets?: Array<number | undefined>;
    skinsEligibility?: Array<boolean>;
    distance?: Distance;
    finalGrossScore: Score;
}

export interface ContactScoringState extends BaseScoringState {
    player: Team | Contact;
    mode: ScoringMode;
    names: Array<string>;
    isReported?: boolean;
    winnerIn?: Array<string>;
    contactInfo: ContactInfo;
    flight?: number;
    roundOrder?: number;
}

export interface ContactRoundsScores extends BaseScoringInfo {
    player: Team | Contact;
    names: Array<string>;
    scoringStates: Array<ContactScoringState | undefined>;
    winnerIn?: Array<string>;
    withdrawn?: boolean;
    disqualified?: boolean;
}

export class CalculatedScores {
    competitionWinnersGross = new Map<number, Array<ContactScoringState>>();
    competitionWinnersNet = new Map<number, Array<ContactScoringState>>();
    competitionScoresGross = new Map<number, Array<ContactScoringState>>();
    competitionScoresNet = new Map<number, Array<ContactScoringState>>();
}

export class CalculatedFlightScores {
    flightWinnersGross = new Array<ContactScoringState>();
    flightWinnersNet = new Array<ContactScoringState>();
    flightScoresGross = new Array<ContactScoringState>();
    flightScoresNet = new Array<ContactScoringState>();
}

export function isCompatibleWithTeamSize(competition: Competition, teamSize: ScoringTeamSize) {
    if (isSideGameScoring(competition.scoring)) {
        return true;
    }
    if (isSkinsScoring(competition.scoring)) {
        return !(competition.scoring.format === ScoringFormatSkins.skins_team && teamSize === 1);
    }
    if (teamSize === 1) {
        return isIndividualScoring(competition.scoring);
    } else {
        return isTeamScoring(competition.scoring);
    }
}

export function isCompatibleCompetitionSkins(competition: Competition, mainCompetition: Competition) {
    if (mainCompetition) {
        if (isSkinsScoring(competition.scoring) && mainCompetition.scoring.format === ScoringFormatTeams.best_ball) {
            return true;
        }
        if (competition.scoring.format === ScoringFormatSkins.skins_individual) {
            if (isTeamScoringExceptBB(mainCompetition.scoring) || mainCompetition.scoring.format === ScoringFormatSkins.skins_team) {
                return false;
            }
        } else if (competition.scoring.format === ScoringFormatSkins.skins_team) {
            if (!isTeamScoringExceptBB(mainCompetition.scoring) && mainCompetition.scoring.format !== ScoringFormatSkins.skins_team) {
                return false;
            }
        }
    }
    return true;
}

export function isCompatibleCompetition(competition: Competition, competitions: Array<Competition>, teamSize: ScoringTeamSize) {
    if (isDistanceScoring(competition.scoring)) {
        return true;
    }
    const mainCompetition = getEventMainCompetition(competitions.filter(comp => comp.id !== competition.id));
    if (isSkinsScoring(competition.scoring)) {
        return isCompatibleCompetitionSkins(competition, mainCompetition);
    }
    if (isTeamScoring(competition.scoring)) {
        if (!mainCompetition) {
            return true;
        }
        if (competition.scoring.format === ScoringFormatTeams.best_ball) {
            return !isTeamScoringExceptBB(mainCompetition.scoring);
        } else {
            return mainCompetition.scoring.format === competition.scoring.format || mainCompetition.scoring.format === ScoringFormatSkins.skins_team;
        }
    } else if (isIndividualScoring(competition.scoring)) {
        if (!mainCompetition) {
            return true;
        }
        return !isTeamScoringExceptBB(mainCompetition.scoring) && mainCompetition.scoring.format !== ScoringFormatSkins.skins_team;
    }
    if (!isCompatibleWithTeamSize(competition, teamSize)) {
        return false;
    }
    const count = competitions.length;
    const pred = (c: Competition) => c.scoring.format === competition.scoring.format ? 1 : 0;
    return teamSize === 1 || count === 0 || count === competitions.map<number>(pred).reduce((sum, cur) => sum + cur, 0);
}

export const getEventMainCompetition = (competitions: Competition[]): Competition => {
    const competitionsMain = competitions.filter(competition => competition.id && isMainScoring(competition.scoring));
    return competitionsMain.filter(competition => isTeamScoring(competition.scoring))[0] ?? competitionsMain[0];
};

export function sortByOrder<T extends { order: number }>(data: Array<T> | Map<string, T>) {
    const sorter = (a: T, b: T) => a.order - b.order;
    return (Array.isArray(data)
        ? data.sort(sorter)
        : Array.from(data.values())).sort(sorter);
}

export function sortCompetitions(competitions: Array<Competition> | Map<string, Competition>, totalMode: boolean) {
    return (Array.isArray(competitions) ? competitions : Array.from(competitions.values())).sort((a, b) => {
        if (totalMode) {
            const diff1 = +isFirstRoundCompetition(a) - +isFirstRoundCompetition(b);
            if (diff1) {
                return diff1;
            }
        }
        const diff2 = (a.roundOrder ?? 0) - (b.roundOrder ?? 0);
        if (diff2) {
            return diff2;
        }
        return a.order - b.order;
    });
}

export const getAppMainCompetition = (competitions: Array<CompetitionLegacy>) => {
    if (!competitions?.length) {
        return null;
    }
    for (const competition of sortByOrder(competitions)) {
        if (isMainScoring(competition.scoring)) {
            return competition;
        }
    }
    return null;
};

export const appScoringStarted = (golfers: Map<string, Contact>, teams: Map<string, Team>, appMainCompetition: Competition | null, mainCompetition?: Competition) => {
    return Boolean(appMainCompetition && mainCompetition && ((isTeamScoringExceptBB(appMainCompetition.scoring) ||
        (appMainCompetition.scoring.format === ScoringFormatSkins.skins_team && mainCompetition.scoring.format !== ScoringFormatTeams.best_ball))
        ? Array.from<Team>(teams.values()) : Array.from<Contact>(golfers.values())).some((p: Team | Contact) => p?.reportedBy?.length));
};

export const compareWinnersIfPresent = (competition: Competition, firstScoreId: string, secondScoreId: string) => {
    if (competition.winners?.length) {
        if (competition.winners.findIndex(winnerInfo => winnerInfo.contactId === firstScoreId
            && winnerInfo.mode === competition.scoring.mode) > -1) {
            return -1;
        }
        if (competition.winners.findIndex(winnerInfo => winnerInfo.contactId === secondScoreId
            && winnerInfo.mode === competition.scoring.mode) > -1) {
            return 1;
        }
    }
    return 0;
};

export function getScoresStats(event: Event, competition: Competition, golferScores: Map<string, Score>, teamScores: Map<string, Score>, reportedScores: Map<string, ReportedScore>, reportedTeamScores: Map<string, ReportedScore>, golfers: Map<string, Contact>, teams: Map<string, Team>, userTimezoneOffset: number) {
    const zoneOffset = - userTimezoneOffset * 1000 * 60;
    const LEGIT_GOLFERS_RATIO = 0.75;
    const LEGIT_SCORES_RATIO = 1;
    const LEGIT_INAPP_GOLFERS_RATIO = 0.5;
    const LEGIT_INAPP_SCORES_RATIO = 0.8;
    const LEGIT_MIN_GOLFERS = 6;
    let totalTeams = 0;
    let totalGolfers = 0;
    let disqualified = 0;
    let withdrawn = 0;
    let full = 0;
    let incomplete = 0;
    let legitDates = 0;
    let legitScores = 0;
    let minLegitScores = 0;
    let minLegitInAppScores = 0;
    let legitInAppScores = 0;
    let legit = false;
    let legitInApp = false;
    const eventDate = event.date + zoneOffset;
    const eventDay = eventDate - (eventDate % Utils.DAY_MILLIS);
    const holesRange = getHolesRange(event.holesType);
    const totalHoles = holesRange.last - holesRange.first;
    const competitionGolfers = golfersOfCompetition(competition, golfers, teams);
    totalGolfers = competitionGolfers.length;
    if (isTeamScoringExceptBB(competition.scoring) || competition.scoring.format === ScoringFormatSkins.skins_team) {
        const competitionTeams = teamsOf(competition, golfers, teams);
        totalTeams = competitionTeams.length;
        for (const team of competitionTeams) {
            const teamScore = teamScores.get(team.id);
            const reportedTeamScore = reportedScores.get(team.id);
            const playedHoles = Scoring.playedHoles(holesRange, teamScore, reportedTeamScore, true);
            const playedInAppHoles = Scoring.playedHoles(holesRange, undefined, reportedTeamScore, true);
            if (team.disqualified) {
                disqualified++;
            } else if (team.withdrawn) {
                withdrawn++;
            } else if (playedHoles < totalHoles) {
                incomplete++;
            } else {
                full++;
            }
            /**
             * Legit events
             * Definition - Minimum 6 golfers in field. Scores entered on the date of the event, for all holes played (9 or 18) for 75% of golfers,
             * scores can be manual or app provided. DQ counts as scored. Within 30 days of first log in. Things that do not matter = Schedule, emails, # of competitions.  
             */
            const updateTime = teamScore?.updateTime ?? 0;
            const scoreDate = updateTime + zoneOffset;
            const legitDate = eventDay - Utils.DAY_MILLIS <= scoreDate && scoreDate <= eventDay + 2 * Utils.DAY_MILLIS;
            const legitScore = team.disqualified || (!team.withdrawn && playedHoles >= (totalHoles * LEGIT_SCORES_RATIO));
            if (legitDate) {
                legitDates++;
                if (legitScore) {
                    legitScores += team.contactIds.length;
                }
            }
            /**
             * Events with legit in-app scoring
             * Definition - Legit event with at least 50% of golfers submitted 80% of their scores via GP app. 
             * Admin edits are allowed. If a golfer is scoring for others, all these golfers count as in-app scoring. 
             */
            if (!team.disqualified && !team.withdrawn && playedInAppHoles >= (totalHoles * LEGIT_INAPP_SCORES_RATIO)) {
                legitInAppScores += team.contactIds.length;
            }
        }
        minLegitScores = Math.floor(totalGolfers * LEGIT_GOLFERS_RATIO);
        minLegitInAppScores = Math.floor(totalGolfers * LEGIT_INAPP_GOLFERS_RATIO);
        legit = legitScores >= minLegitScores && totalGolfers >= LEGIT_MIN_GOLFERS;
        legitInApp = legitInAppScores >= minLegitInAppScores;
    } else {
        for (const golfer of competitionGolfers) {
            const golferScore = golferScores.get(golfer.id);
            const reportedScore = reportedScores.get(golfer.id);
            const playedHoles = Scoring.playedHoles(holesRange, golferScore, reportedScore, true);
            const playedInAppHoles = Scoring.playedHoles(holesRange, undefined, reportedScore, true);
            if (golfer.disqualified) {
                disqualified++;
            } else if (golfer.withdrawn) {
                withdrawn++;
            } else if (playedHoles < totalHoles) {
                incomplete++;
            } else {
                full++;
            }
            /**
             * Legit events
             * Definition - Minimum 6 golfers in field. Scores entered on the date of the event, for all holes played (9 or 18) for 75% of golfers,
             * scores can be manual or app provided. DQ counts as scored. Within 30 days of first log in. Things that do not matter = Schedule, emails, # of competitions.  
             */
            const updateTime = golferScore?.updateTime ?? 0;
            const scoreDate = updateTime + zoneOffset;
            const legitDate = eventDay - Utils.DAY_MILLIS <= scoreDate && scoreDate <= eventDay + 2 * Utils.DAY_MILLIS;
            const legitScore = golfer.disqualified || (!golfer.withdrawn && playedHoles >= (totalHoles * LEGIT_SCORES_RATIO));
            if (legitDate) {
                legitDates++;
                if (legitScore) {
                    legitScores++;
                }
            }
            /**
             * Events with legit in-app scoring
             * Definition - Legit event with at least 50% of golfers submitted 80% of their scores via GP app.
             * Admin edits are allowed. If a golfer is scoring for others, all these golfers count as in-app scoring. 
             */
            if (!golfer.disqualified && !golfer.withdrawn && playedInAppHoles >= (totalHoles * LEGIT_INAPP_SCORES_RATIO)) {
                legitInAppScores++;
            }
        }
        minLegitScores = Math.floor(totalGolfers * LEGIT_GOLFERS_RATIO);
        minLegitInAppScores = Math.floor(totalGolfers * LEGIT_INAPP_GOLFERS_RATIO);
        legit = legitScores >= minLegitScores && totalGolfers >= LEGIT_MIN_GOLFERS;
        legitInApp = legitInAppScores >= minLegitInAppScores;
    }
    return {
        full,
        totalHoles,
        totalGolfers,
        totalTeams,
        disqualified,
        withdrawn,
        legit,
        legitInApp,
        incomplete,
        legitDates,
        legitScores,
        minLegitScores,
        legitInAppScores,
        minLegitInAppScores,
    };
}

const contactDetailFields = [
    'avatar',
    'firstName',
    'handicapIndex',
    'email',
    'tee',
    'homeCourseOrCity',
    'phone',
    'notes',
    'handicapId',
    'reportedBy',
    'paymentId',
    'feePaid',
    'feePaidDate'
] as Array<keyof ContactDetails>;

export function toGolfer(contactDetails: ContactDetails, eventOrRound: EventBase): Contact {
    const contactBase = {
        id: contactDetails.id,
        lastName: contactDetails.lastName,
        gender: contactDetails.gender,
        hidden: false
    } as any;
    for (const fieldToCopy of contactDetailFields) {
        const val = contactDetails[fieldToCopy];
        if (val !== null && val !== undefined) {
            contactBase[fieldToCopy] = val;
        }
    }
    const contact = contactBase as Contact;
    if (contactDetails.roundTees) {
        contact.tee = contactDetails.roundTees[eventOrRound.id];
    }
    return contact;
}

export function toName(golfer: Contact): string {
    if (golfer.firstName) {
        return `${golfer.lastName} ${golfer.firstName}`;
    }
    return `${golfer.lastName}`;
}

export function isUsOrMexico(countryCode: string | null) {
    return countryCode === 'US' || countryCode === 'MX';
}

export function correctHandicapSystem(handicapSystem?: HandicapSystem) {
    if (handicapSystem === 'CONGU') {
        handicapSystem = 'WHS_UK';
    } else if (handicapSystem === 'GA') {
        handicapSystem = 'WHS_AU';
    } else if (handicapSystem !== 'WHS' && handicapSystem !== 'WHS_UK' && handicapSystem !== 'WHS_AU' && handicapSystem !== 'SMPLFD') {
        handicapSystem = 'WHS';
    }
    return handicapSystem;
}

export type USGAGolferInfo = {
    handicapIndex: number,
    homeCity?: string
};

export type UserInfo = {
    email?: string;
    name?: string;
    lastBadge?: string;
    lastBanner?: string;
    loginId?: string;
    payPalCurrencyCode?: string;
    payPalEmailAddress?: string;
};

export enum PaymentStatus {
    SUCCESSFUL_PAYMENT,
    SUCCESSFUL_PAYMENT_WITH_EXCEPTION,
    PAYMENT_FAILURE,
    PAY_LATER,
    NONE
}

export interface SpreadsheetImportResult {
    added: number;
    updated: number;
    rejected: number;
    error?: string;
    contacts: Array<ContactDetails>;
    statuses: Map<number, string>;
}
