import * as React from 'react';
import { DialogContent, DialogActions, Typography, Divider } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Course, SimpleCourse, Tee, ResultStatus, getCourseName, isCompoundCourse, EventBase } from '../../../types/EventTypes';
import { iCourse, oCourse, teeCourse } from '../../Courses';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import { ListElem } from '../../../common/Misc';
import { getTeeInfo, getTeeShortName, getTeeLongName } from '../../../scoring/handicap';
import LabeledField from '../../../common/form/LabeledField';
import EditTeeDialog from './EditTeeDialog';
import TeesProvider from './TeesProvider';
import { styles } from '../../../styles';

interface TeeItemProps {
    tee: Tee;
    compound: boolean;
    handleEdit: (tee: Tee) => void;
}

const TeeItem = withStyles(styles)((props: TeeItemProps) => {
    const { tee, handleEdit, compound } = props;
    return (
        <LabeledField noLabel value={(<ListElem title={compound ? getTeeLongName(tee) : getTeeShortName(tee)} subtitle={getTeeInfo(tee)} />)} edit={() => handleEdit(tee)} />
    );
});

type Props = {
    event: EventBase;
    course: Course;
    close: (changed: boolean) => void;
} & WithStyles<typeof styles>;

type State = {
    tees: Array<Tee>;
    editingTee?: Tee;
    facility?: SimpleCourse;
    changed: boolean;
    teesLoadStatus: ResultStatus;
};

class EditTeesListDialog extends React.Component<Props, State> {

    private readonly teesLoader: React.RefObject<TeesProvider>;

    constructor(props: Props) {
        super(props);
        this.teesLoader = React.createRef();
        this.state = {
            teesLoadStatus: 'in_progress',
            changed: false,
            tees: []
        };
    }

    private handleClose = () => this.props.close(this.state.changed);
    private editTee = (tee: Tee) => this.setState({ editingTee: tee, facility: teeCourse(tee, this.props.course) });
    private addTee = (facility: SimpleCourse) => this.setState({ editingTee: { name: '', facilityId: facility.id, facilityName: facility.name } as Tee, facility });
    private loadTees = () => this.teesLoader.current?.loadTees();
    private teesLoaded = (teesLoadStatus: ResultStatus, tees?: Array<Tee>) => this.setState({ tees: tees || [], teesLoadStatus });

    private handleCloseEditTee = (changed: boolean) => {
        this.setState({ editingTee: undefined, facility: undefined });
        if (changed) {
            this.setState({ changed });
            this.loadTees();
        }
    }

    render() {
        const { classes, event, course } = this.props;
        const { editingTee, tees, teesLoadStatus, facility } = this.state;
        const courseName = getCourseName(course);
        const teesVisible = tees.filter(tee => !tee.deleted);
        const facility1 = oCourse(course);
        const facility2 = iCourse(course);
        const compound = isCompoundCourse(course);
        const singleFacility = !isCompoundCourse(course) ? course : (facility1 && facility1.id === facility2?.id) ? facility1 : undefined;
        return (
            <XSMobileDialog open={true} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                <DialogAppBar label="Course scorecard" close={this.handleClose} />
                <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 8 }}>
                    <Typography variant="h6" gutterBottom>{courseName}</Typography>
                    <Typography>Tees</Typography>
                    <Divider />
                </div>
                <DialogContent>
                    {teesVisible.map(tee => <TeeItem key={tee.id + tee.facilityId} tee={tee} compound={compound} handleEdit={this.editTee} />)}
                    {(tees.length === 0 && teesLoadStatus === 'in_progress') && <Typography>Loading...</Typography>}
                    {(teesVisible.length === 0 && teesLoadStatus === 'ok') && <Typography>No tees found in the database</Typography>}
                    {(teesVisible.length === 0 && teesLoadStatus === 'error') && <Typography>Failed to load tees from the database (<span className={classes.linkBluePointer} onClick={this.loadTees}>retry</span>)</Typography>}
                </DialogContent>
                <DialogActions>
                    <span style={{ paddingLeft: 12 }} />
                    {singleFacility && <AppButton color="primary" style={{ marginLeft: 8 }} className={classes.iconButton} onClick={() => this.addTee(singleFacility)}>+ Add Tee</AppButton>}
                    {facility1 && facility1.id !== facility2?.id && <AppButton color="primary" className={classes.iconButton} style={{ marginRight: 8 }} onClick={() => this.addTee(facility1)}>+ Add Tee ({facility1.name})</AppButton>}
                    {facility2 && facility2.id !== facility1?.id && <AppButton color="primary" className={classes.iconButton} style={{ marginRight: 8 }} onClick={() => this.addTee(facility2)}>+ Add Tee ({facility2.name})</AppButton>}
                    <span style={{ flex: '1 1 0%' }} />
                    <AppButton color="info" className={classes.iconButton} onClick={this.handleClose}>Close</AppButton>
                </DialogActions>
                {(editingTee && facility) &&
                    <EditTeeDialog event={event} facility={facility} tee={editingTee} tees={tees} close={this.handleCloseEditTee} />}
                <TeesProvider event={event} course={course} onResult={this.teesLoaded} ref={this.teesLoader} includeDeleted dontCombineCompound />
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(EditTeesListDialog);
