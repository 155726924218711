import * as React from 'react';
import { Badge } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Event, EventData } from '../../../types/EventTypes';
import { everybodyIsPairedEventOrRounds, everybodyIsScheduledEventOrRounds, golfersAvailableEventOrRounds } from '../../Event';
import LinkTabs, { Tab } from '../common/LinkTabs';
import GolfersList from './list/GolfersList';
import InvitesList from './list/InvitesList';
import TeamsList from './list/TeamsList';
import ScheduleList from './pairings/ScheduleList';
import { styles } from '../../../styles';
import Typography from "@mui/material/Typography";
import { AppColors } from "../../../main/Theme";
import { withS } from 'src/util/utility';

interface Properties {
    event: Event;
    eventData: EventData;
    initial?: string;
    onSelectedTab: (subtab: string) => void;
}

const SubLabelStyle: React.CSSProperties = {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: '18px',
    color: AppColors.webGrey
};

function GolfersTab(props: Properties & WithStyles<typeof styles>) {
    const { event, eventData } = props;
    const golfersSubLabel = withS(Array.from(eventData.golfers.values()).filter(c => !c.hidden).length, 'golfer');
    const { allTeamsPaired, teamsCount } = everybodyIsPairedEventOrRounds(event, eventData);
    const { allScheduled, groupsCount } = everybodyIsScheduledEventOrRounds(event, eventData);
    const teamsSubLabel = event.teamSize > 1 ? teamsCount > 0 ? withS(teamsCount, 'team') : 'not paired' : 'no teams';
    const groupsSubLabel = groupsCount > 0 ? withS(groupsCount, 'group') : 'not set';
    const invitesSubLabel = `${Array.from(eventData.invitedContacts.values()).length} invited`;
    const tabs: Array<Tab<string>> = [{
        id: 'list',
        label: <Badge color="error" variant="dot" overlap="rectangular" invisible={golfersAvailableEventOrRounds(event, eventData)}>LIST &nbsp;</Badge>,
        subLabel: <Typography style={SubLabelStyle}>{golfersSubLabel}</Typography>,
        node: <GolfersList event={event} eventData={eventData} />
    }];
    tabs.push({
        id: 'teams',
        label: <Badge color="error" variant="dot" overlap="rectangular" invisible={allTeamsPaired}>TEAMS &nbsp;</Badge>,
        subLabel: <Typography style={SubLabelStyle}>{teamsSubLabel}</Typography>,
        node: <TeamsList event={event} eventData={eventData} />
    });
    tabs.push({
        id: 'schedule',
        label: <Badge color="error" variant="dot" overlap="rectangular" invisible={allScheduled && groupsCount > 0}>SCHEDULE &nbsp;</Badge>,
        subLabel: <Typography style={SubLabelStyle}>{groupsSubLabel}</Typography>,
        node: <ScheduleList event={event} eventData={eventData} />
    });
    tabs.push({
        id: 'invites',
        label: 'INVITES',
        subLabel: <Typography style={SubLabelStyle}>{invitesSubLabel}</Typography>,
        node: <InvitesList event={event} eventData={eventData} />
    });
    return <LinkTabs initial={props.initial || 'list'} tabs={tabs} onSelectedTab={props.onSelectedTab} classes={props.classes} />;
}

export default withStyles(styles)(GolfersTab);
