import * as React from 'react';
import { DialogActions, Typography } from '@mui/material';
import LabeledField from './LabeledField';
import FormLinkWithDialog from './FormLinkWithDialog';
import { formatDateUniversal } from 'src/event/Event';
import { XSMobileDialog } from '../dialog/MobileDialog';
import DialogAppBar from '../dialog/DialogAppBar';
import AppButton from '../components/AppButton';
import MaterialDate from '../MaterialDate';
import { Spacing } from '../Misc';
import { EVENT_DATETIME_FORMAT, EVENT_DATETIME_FORMAT_DLG, EVENT_TIME_FORMAT } from 'src/util/config';
import { TimeTextField } from "../TimeTextField";
import { AppColors } from 'src/main/Theme';
import { BoxedInfoElement } from 'src/redux/ReduxConfig';

type DlgProps = {
    eventDate: number;
    teeStartTime: number;
    onClose: () => void;
    onSave: (v: number) => void;
};

type DlgState = {
    eventDate: number;
};

const EditDateTimeDialog = class extends React.Component<DlgProps, DlgState> {
    state: DlgState = {
        eventDate: this.props.eventDate
    };
    render() {
        const { onClose, onSave, teeStartTime } = this.props;
        const { eventDate } = this.state;
        const note = `Tee times start at ${formatDateUniversal(teeStartTime, EVENT_TIME_FORMAT)}. You can edit it in the Golfers → Schedule section.`;
        return <React.Fragment>
            <XSMobileDialog open={true} onClose={onClose} maxWidth="xs" fullWidth={true}>
                <DialogAppBar label="Date" close={onClose} />
                <div style={{ padding: '16px' }}>
                    <MaterialDate
                        icon
                        enableUnderline
                        label="Event date"
                        value={eventDate}
                        format={EVENT_DATETIME_FORMAT_DLG}
                        onChange={date => this.setState({ eventDate: date.getTime() })}
                        popperPlacement={'bottom'}
                        style={{ width: '100%', maxWidth: 400 }} />
                    <Spacing />
                    <TimeTextField date={eventDate} onTimeSelected={date => this.setState({ eventDate: date.getTime() })}
                        label="Event start time" />
                    <Spacing />
                    <BoxedInfoElement iconColor={AppColors.webBlue500}>{note}</BoxedInfoElement>
                </div>
                <DialogActions>
                    <AppButton color="info" onClick={onClose}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={() => onSave(eventDate)}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        </React.Fragment>;
    }
};

type DateTimeLabelProps = {
    value: number;
};

const DateTimeLabel = class extends React.Component<DateTimeLabelProps> {
    render() {
        const { value } = this.props;
        const msg = (
            <React.Fragment>
                Multi-day events and league play are among many other features on our development roadmap. Please vote&nbsp;
                <a href="https://forms.gle/qtvGvbVyreFrbbq57" target="blank">here</a> for these features and others you would like to see prioritized. We look forward to your valuable feedback.
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <Typography variant="body1">{formatDateUniversal(value, EVENT_DATETIME_FORMAT)}</Typography>
                <FormLinkWithDialog text="Multi-day event or league?" msg={msg} />
            </React.Fragment>
        );
    }
};

type Props = {
    value: number;
    label: string;
    teeStartTime: number;
    save: (v: number) => void;
};

type State = { dialogOpened?: boolean };

export const EditDateTimeField = class extends React.Component<Props, State> {
    state: State = {}

    onClose = () => this.setState({ dialogOpened: false });
    onOpen = () => this.setState({ dialogOpened: true });
    onSave = (dateTime: number) => this.setState({ dialogOpened: false }, () => this.props.save(dateTime));

    render() {
        const { label, value, teeStartTime } = this.props;
        const { dialogOpened } = this.state;
        return (
            <React.Fragment>
                <LabeledField
                    label={label}
                    value={<DateTimeLabel value={value} />}
                    edit={this.onOpen}
                    editMode
                />
                {dialogOpened && <EditDateTimeDialog
                    onClose={this.onClose}
                    onSave={this.onSave}
                    eventDate={value}
                    teeStartTime={teeStartTime} />}
            </React.Fragment >
        );
    }
};
