import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { List, ListItem, Typography } from '@mui/material';
import { detectMob } from '../../../util/react_utils';
import * as Backend from '../../../util/firebase';
import {
    Event, EventData, Competition, Units, Distance, ReportedScore, Score, isMainScoring, isSkinsScoring,
    isNetMode, isGrossMode, isGrossPayouts, isNetPayouts, Portal, Team, Contact, GolferGroup, EventBase, isRound, Round
} from '../../../types/EventTypes';
import { ContactGroup, prepareInviteCodes } from '../../Event';
import { netCompetition, grossCompetition } from '../../../scoring/scoring'
import { createScheduleDoc, createScoringDoc, createScorecardsDoc, createGolfersDoc, createCartSignsDoc, createMoneyListDoc, getGolferInfo } from '../../EventFormat';
import { getSchedule, getCartSigns } from '../../TeeTimes';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import AddGolfersDialog from '../../tabs/common/AddGolfersDialog';
import { withProgress } from '../../../util/ProgressPromise';
import { hideAlert, showAlertProps } from '../../../redux/ReduxConfig';
import { styles } from '../../../styles';
import { getSameNameGolfersIds } from "../../../contact/Contact";

const useBadge = false;

export function printDoc(doc: any, fileName: string) {
    if (doc) {
        const nav = (window.navigator as any);
        // work around according known Edge 10+ bug https://github.com/bpampuch/pdfmake/issues/693#issuecomment-551041722
        if (nav?.msSaveOrOpenBlob) {
            doc.getBlob((blob: Blob) => nav?.msSaveOrOpenBlob(blob, fileName));
        } else if (detectMob()) {
            doc.download(fileName);
        } else {
            doc.print();
        }
    }
}

export async function printGolfers(eventOrRound: EventBase, golfers?: Map<string, Contact>, teams?: Map<string, Team>, groups?: Array<GolferGroup>, competitions?: Array<Competition>) {
    golfers ??= await Backend.mapEntities<Contact>(Backend.eventGolfersQuery(eventOrRound.id));
    groups ??= await Backend.getEntities<GolferGroup>(Backend.golferGroupDb(eventOrRound.id));
    teams ??= await Backend.mapEntities<Team>(Backend.golferTeamDb(eventOrRound.id));
    competitions ??= await Backend.getEntities<Competition>(Backend.eventCompetitionsQuery(eventOrRound.id));
    const doc = createGolfersDoc(eventOrRound, competitions, golfers, teams, groups);
    printDoc(doc, eventOrRound.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-golfers.pdf');
}

export async function printSchedule(eventOrRound: EventBase, golfers?: Map<string, Contact>, teams?: Map<string, Team>, groups?: Array<GolferGroup>) {
    golfers ??= await Backend.mapEntities<Contact>(Backend.golferDb(eventOrRound.id));
    groups ??= await Backend.getEntities<GolferGroup>(Backend.golferGroupDb(eventOrRound.id));
    teams ??= await Backend.mapEntities<Team>(Backend.golferTeamDb(eventOrRound.id));
    const schedule = getSchedule(eventOrRound, groups, golfers, teams);
    if (schedule) {
        const doc = createScheduleDoc(eventOrRound, schedule, golfers);
        printDoc(doc, eventOrRound.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-schedule.pdf');
    }
}

export async function printResults(event: Event, eventOrRound: EventBase, golfers?: Map<string, Contact>, teams?: Map<string, Team>, groups?: Array<GolferGroup>) {
    golfers ??= await Backend.mapEntities<Contact>(Backend.eventGolfersQuery(eventOrRound.id));
    groups ??= await Backend.getEntities<GolferGroup>(Backend.golferGroupDb(eventOrRound.id));
    teams ??= await Backend.mapEntities<Team>(Backend.eventTeamsQuery(eventOrRound.id));
    const { units } = await withProgress(Backend.getEntity<{ id: string, units?: Units }>(Backend.userPubFields(event.userId)));
    const golferScores = await Backend.mapEntities<Score>(Backend.golferScoresDb(eventOrRound.id));
    const reportedScores = await Backend.mapEntities<ReportedScore>(Backend.reportedGolferScoresDb(eventOrRound.id));
    const teamScores = await Backend.mapEntities<Score>(Backend.golferTeamScoresDb(eventOrRound.id));
    const reportedTeamScores = await Backend.mapEntities<ReportedScore>(Backend.reportedTeamScoresDb(eventOrRound.id));
    const distances = await Backend.mapEntities<Distance>(Backend.golferDistancesDb(eventOrRound.id));
    const competitions = await Backend.getEntities<Competition>(Backend.eventCompetitionsQuery(eventOrRound.id));
    const competitionsSplit: Array<Competition> = [];
    competitions.forEach(competition => {
        if (isSkinsScoring(competition.scoring)) {
            if (isNetMode(competition.scoring.mode)) {
                competitionsSplit.push(netCompetition(competition));
            }
            if (isGrossMode(competition.scoring.mode)) {
                competitionsSplit.push(grossCompetition(competition));
            }
        } else {
            competitionsSplit.push(competition);
        }
    });
    const doc = createScoringDoc(eventOrRound, competitionsSplit, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, distances, units);
    printDoc(doc, event.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-results.pdf');
}

export async function printCartSigns(eventOrRound: EventBase, golfers?: Map<string, Contact>, teams?: Map<string, Team>, groups?: Array<GolferGroup>) {
    golfers ??= await Backend.mapEntities<Contact>(Backend.eventGolfersQuery(eventOrRound.id));
    groups ??= await Backend.getEntities<GolferGroup>(Backend.golferGroupDb(eventOrRound.id));
    teams ??= await Backend.mapEntities<Team>(Backend.eventTeamsQuery(eventOrRound.id));
    const groupCodes = await prepareInviteCodes(groups, eventOrRound.id);
    const cartSigns = getCartSigns(eventOrRound, groups, golfers, teams);
    const doc = createCartSignsDoc(eventOrRound, cartSigns, getSameNameGolfersIds(Array.from(golfers.values())), groupCodes);
    printDoc(doc, eventOrRound.publicId + (isRound(eventOrRound) ? `-round${eventOrRound.roundOrder}` : '') + '-cartsigns.pdf');
}

export function getImageData(image: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d')!;
    context.save();
    context.fillStyle = '#bbbbbb';
    context.beginPath();
    context.arc(canvas.width * 3 / 4, canvas.height / 2, 10, 0, Math.PI * 2, true);
    // context.arc(canvas.width / 2, canvas.height, 30, 0, Math.PI * 2, true);
    context.closePath();
    context.fill();
    // context.drawImage(image, 0, 0);
    context.fillStyle = '#eeeeee';
    context.globalCompositeOperation = 'destination-over';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
    return canvas.toDataURL('data:image/png');
}

export function getImage(_url: string): Promise<string> {
    return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
            resolve(getImageData(image));
        };
        image.width = 80;
        image.height = 80;
        // image.src = url;
        resolve(getImageData(image));
    });
}

export async function printMoney(event: Event, eventOrRound: EventBase) {
    const golfers = await Backend.mapEntities<Contact>(Backend.golferDb(eventOrRound.id));
    const groups = await Backend.getEntities<GolferGroup>(Backend.golferGroupDb(eventOrRound.id));
    const teams = await Backend.mapEntities<Team>(Backend.golferTeamDb(eventOrRound.id));
    const competitions = await Backend.getEntities<Competition>(Backend.eventCompetitionsQuery(eventOrRound.id));
    const golferScores = await Backend.mapEntities<Score>(Backend.golferScoresDb(eventOrRound.id));
    const teamScores = await Backend.mapEntities<Score>(Backend.golferTeamScoresDb(eventOrRound.id));
    const distances = await Backend.mapEntities<Distance>(Backend.golferDistancesDb(eventOrRound.id));
    const reportedScores = await Backend.mapEntities<ReportedScore>(Backend.reportedGolferScoresDb(eventOrRound.id));
    const reportedTeamScores = await Backend.mapEntities<ReportedScore>(Backend.reportedTeamScoresDb(eventOrRound.id));
    const doc = createMoneyListDoc(event, competitions, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, distances);
    printDoc(doc, event.publicId + '-money.pdf');
}

type PrintScorecardsDialogProps = {
    event: Event;
    selectedRound?: Round;
    teams: Map<string, Team>;
    groups: Array<GolferGroup>;
    golfers: Map<string, Contact>;
    competitions: Array<Competition>;
};

const PrintScorecardsDialog = React.forwardRef(({ event, selectedRound, teams, groups, golfers, competitions, onClose }: PrintScorecardsDialogProps & { onClose: () => void }, ref: React.ForwardedRef<any>) => {
    const eventOrRound = selectedRound ?? event;
    const _handlePrintScorecards = async (contacts: Array<ContactGroup>, notes?: string) => {
        if (contacts.length === 0) {
            return;
        }
        const groupCodes = await prepareInviteCodes(groups, selectedRound?.id ?? event.id);
        if (useBadge) {
            const portal = await withProgress(Backend.getEntity<Portal>(Backend.portalFields(event.id)));
            if (portal.badgeUrl) {
                const dataUrl = await withProgress(getImage(portal.badgeUrl));
                await _printScorecards(contacts, groupCodes, dataUrl, notes);
            } else {
                await _printScorecards(contacts, groupCodes, '', notes);
            }
        } else {
            await _printScorecards(contacts, groupCodes, '', notes);
        }
        onClose();
    }
    const _printScorecards = async (contacts: Array<ContactGroup>, groupCodes: Map<string, string>, badgeData?: string, notes?: string) => {
        const golferScores = await Backend.mapEntities<Score>(Backend.golferScoresDb(eventOrRound.id));
        const teamScores = await Backend.mapEntities<Score>(Backend.golferTeamScoresDb(eventOrRound.id));
        const distances = await Backend.mapEntities<Distance>(Backend.golferDistancesDb(eventOrRound.id));
        const doc = createScorecardsDoc(eventOrRound, badgeData, contacts, golferScores, teamScores, golfers, teams, groups, distances, competitions, groupCodes, false, notes);
        printDoc(doc, event.publicId + (selectedRound ? `-round${selectedRound.roundOrder}` : '') + '-scorecards.pdf');
    }
    return <>
        <AddGolfersDialog
            open
            selectAll
            withNotes
            label={'Print Scorecards'}
            okLabel={'Print'}
            {...{ eventOrRound: event, teams, groups, golfers, roster: new Map(), loadedTeams: 1, loadedGroups: 1, loadedGolfers: 1, loadedRoster: 1 }}
            golferDB={'GOLFER_GROUPS'}
            handleAddGolfers={_handlePrintScorecards}
            handleCancel={onClose}
        />
    </>;
});

export async function printScorecards(event: Event, eventOrRound: Event | Round, golfers?: Map<string, Contact>, teams?: Map<string, Team>, groups?: Array<GolferGroup>, competitions?: Array<Competition>) {
    golfers ??= await Backend.mapEntities<Contact>(Backend.golferDb(eventOrRound.id));
    groups ??= await Backend.getEntities<GolferGroup>(Backend.golferGroupDb(eventOrRound.id));
    teams ??= await Backend.mapEntities<Team>(Backend.golferTeamDb(eventOrRound.id));
    competitions ??= await Backend.getEntities<Competition>(Backend.eventCompetitionsQuery(eventOrRound.id));
    const eventInfo = getGolferInfo(event, competitions, golfers, teams, groups);
    const noLength = eventInfo.getTees().findIndex(t => !t.len || t.len.findIndex(l => l > 0) < 0) >= 0;
    const props = {
        event,
        teams,
        groups,
        golfers,
        competitions,
        selectedRound: 'roundOrder' in eventOrRound ? eventOrRound : undefined
    };
    const next = () => {
        const printPrompt = <PrintScorecardsDialog
            {...props}
            onClose={() => hideAlert({ content: printPrompt, ownContent: true, buttons: [] })}
        />;
        showAlertProps({ content: printPrompt, ownContent: true, buttons: [] });
    }
    let scorecardDontShowTeeWarning = true;
    if (noLength && !event.scorecardDontShowTeeWarning) {
        showAlertProps({
            content: `One or more of the tees is missing hole lengths, these tees will not be shown on the printed scorecards. Add lengths under settings, course, view/edit scorecard, select tee.`,
            buttons: [{
                title: 'Cancel'
            }, {
                title: 'OK',
                color: 'secondary',
                action: async () => {
                    if (scorecardDontShowTeeWarning) {
                        await withProgress(Backend.updateOrAdd(Backend.eventsDb, { id: event.id, exists: true, scorecardDontShowTeeWarning: true }).catch());
                    }
                    next();
                }
            }],
            dontShowAgain: scorecardDontShowTeeWarning,
            dontShowHandler: (v: boolean) => {
                scorecardDontShowTeeWarning = v;
            }
        });
    } else {
        next();
    }
}

interface State {
    scorecardDontShowTeeWarning?: boolean;
}

type Props = { event: Event; eventData: EventData; } & WithStyles<typeof styles>;

class PrintTab extends React.Component<Props, State> {
    state: State = {
    };

    private printScorecards = (roundOrder?: number) => () => {
        const { event } = this.props;
        const { rounds } = this.props.eventData;
        withProgress(printScorecards(event, rounds.find(round => round.roundOrder === roundOrder) ?? event));
    }

    private printSchedule = (roundOrder?: number) => () => {
        const { event } = this.props;
        const { rounds } = this.props.eventData;
        withProgress(printSchedule(rounds.find(round => round.roundOrder === roundOrder) ?? event));
    }

    private printResults = (roundOrder?: number) => () => {
        const { event } = this.props;
        const { rounds } = this.props.eventData;
        withProgress(printResults(event, rounds.find(round => round.roundOrder === roundOrder) ?? event));
    }

    private printGolfers = () => {
        const { event } = this.props;
        const { rounds } = this.props.eventData;
        withProgress(printGolfers(rounds.find(round => round.roundOrder === 1) ?? event));
    }

    private printCartSigns = (roundOrder?: number) => () => {
        const { event } = this.props;
        const { rounds } = this.props.eventData;
        withProgress(printCartSigns(rounds.find(round => round.roundOrder === roundOrder) ?? event));
    }

    private printMoney = () => {
        const { event } = this.props;
        printMoney(event, event);
    }

    private eventOrRound() {
        const { event } = this.props;
        const { rounds } = this.props.eventData;
        return rounds.length > 0 ? rounds[0] : event;
    }

    render() {
        const { classes, eventData } = this.props;
        const { rounds, competitionsMap, golfersMap, groupsMap } = eventData;
        const eventOrRound = this.eventOrRound();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const competitions = competitionsMap.get(eventOrRound.id) ?? [];
        const noSchedule = groups.filter(g => g.contactIds.length > 0).length === 0;
        const payoutsCompetitions = competitions.filter(competition => isMainScoring(competition.scoring) && (isGrossPayouts(competition) || isNetPayouts(competition)));
        const noScorings = competitions.filter(comp => isMainScoring(comp.scoring)).length === 0;
        const noGolfers = golfers.size === 0;
        const noMoney = golfers.size === 0 || payoutsCompetitions.length === 0;
        return (
            <List disablePadding className={classes.listRootGreyRound}>
                <Typography sx={{ fontWeight: 600, marginLeft: '20px' }}>GENERAL</Typography>
                <ListItem sx={{ paddingTop: "0px" }}>
                    <ButtonBar>
                        <AppButton color="secondary" onClick={this.printGolfers} disabled={noGolfers}>Print Golfers List</AppButton>
                        {rounds.length === 0 && <>
                            <AppButton color="secondary" onClick={this.printScorecards()} disabled={noSchedule || noScorings}>Print Scorecards</AppButton>
                            <AppButton color="secondary" onClick={this.printSchedule()} disabled={noSchedule}>Print Schedule</AppButton>
                            <AppButton color="secondary" onClick={this.printResults()} disabled={noScorings}>Print Results</AppButton>
                            <AppButton color="secondary" onClick={this.printCartSigns()} disabled={noSchedule}>Print Cart Signs</AppButton>
                        </>}
                        <AppButton color="secondary" onClick={this.printMoney} disabled={noMoney}>Print Money List</AppButton>
                    </ButtonBar >
                </ListItem>
                {rounds.map(round => <div key={round.roundOrder}>
                    <Typography sx={{ fontWeight: 600, marginLeft: '20px' }}>ROUND {round.roundOrder}</Typography>
                    <ListItem sx={{ paddingTop: "0px" }}>
                        <ButtonBar>
                            <AppButton color="info" className={classes.buttonWhite} onClick={this.printScorecards(round.roundOrder)} disabled={noSchedule || noScorings}>Print Scorecards</AppButton>
                            <AppButton color="info" className={classes.buttonWhite} onClick={this.printSchedule(round.roundOrder)} disabled={noSchedule}>Print Schedule</AppButton>
                            <AppButton color="info" className={classes.buttonWhite} onClick={this.printResults(round.roundOrder)} disabled={noScorings}>Print Results</AppButton>
                            <AppButton color="info" className={classes.buttonWhite} onClick={this.printCartSigns(round.roundOrder)} disabled={noSchedule}>Print Cart Signs</AppButton>
                        </ButtonBar >
                    </ListItem>
                </div>)}
            </List>
        );
    }
}

export default withStyles(styles)(PrintTab);
